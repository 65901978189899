import { ChainId } from "@uniswap/sdk-core";
import { UNIVERSAL_ROUTER_ADDRESS } from "@uniswap/universal-router-sdk";
import { isSupportedChain } from "constants/chains";
import gql from "graphql-tag";

import { useNftUniversalRouterAddressQuery } from "../__generated__/types-and-hooks";

export function universalRouterAddressHelper(chainId: number) {
  if (chainId == 42) {
    // return "0xD924E9a784b4C251A6F285b3ea24151e7CF49d64";
    return "0xDeA7993A2740eEBB4FA4B42EA069397ce9C1Ce5a";
  }
  if (chainId == 4201) {
    // return "0xD924E9a784b4C251A6F285b3ea24151e7CF49d64";
    return "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506";
  }
  return UNIVERSAL_ROUTER_ADDRESS(chainId);
}

gql`
  query NftUniversalRouterAddress($chain: Chain = ETHEREUM) {
    nftRoute(chain: $chain, senderAddress: "", nftTrades: []) {
      toAddress
    }
  }
`;

export function getURAddress(
  chainId?: number,
  nftURAddress?: string
): string | undefined {
  if (!chainId) return undefined;
  // if mainnet and on NFT flow, use the contract address returned by GQL
  if (chainId === ChainId.MAINNET) {
    return nftURAddress ?? universalRouterAddressHelper(chainId);
  }
  return isSupportedChain(chainId)
    ? universalRouterAddressHelper(chainId)
    : undefined;
}

export function useNftUniversalRouterAddress() {
  const { data, loading } = useNftUniversalRouterAddressQuery({
    // no cache because a different version of nftRoute query is going to be called around the same time
    fetchPolicy: "no-cache",
  });

  return {
    universalRouterAddress: data?.nftRoute?.toAddress,
    universalRouterAddressIsLoading: loading,
  };
}
