import {
  ChainId,
  Currency,
  Ether,
  NativeCurrency,
  Token,
  UNI_ADDRESSES,
  WETH9,
} from "@uniswap/sdk-core";
import invariant from "tiny-invariant";
import { CustomChainId } from "./chains";

export const NATIVE_CHAIN_ID = "NATIVE";

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18;

export const USDC_MAINNET = new Token(
  ChainId.MAINNET,
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  6,
  "USDC",
  "USD//C"
);

export const USDC_LUKSO = {
  [CustomChainId.LUKSO_MAINNET]: new Token(
    CustomChainId.LUKSO_MAINNET,
    "0xe950442987921442fec8c8d1c4cb278832e29c0c",
    18,
    "LUSDC",
    "LUSDC"
  ),
  [CustomChainId.LUKSO_TESTNET]: new Token(
    CustomChainId.LUKSO_TESTNET,
    "0xc959CCcc3602C41ae7142f9038ADb11022B0ACcD",
    18,
    "USDT",
    "LUSDT"
  ),
};

export const DAI = new Token(
  ChainId.MAINNET,
  "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  18,
  "DAI",
  "Dai Stablecoin"
);

export const USDT = new Token(
  ChainId.MAINNET,
  "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  "USDT",
  "Tether USD"
);

export const WBTC = new Token(
  ChainId.MAINNET,
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  8,
  "WBTC",
  "Wrapped BTC"
);

export const WLYX_LUKSO_MAINNET = new Token(
  CustomChainId.LUKSO_MAINNET,
  "0x2dB41674F2b882889e5E1Bd09a3f3613952bC472",
  18,
  "wLYX",
  "Wrapped LYX"
);

export const WLYX_LUKSO_TESTNET = new Token(
  CustomChainId.LUKSO_TESTNET,
  "0x1244985EAb2fC01d07f20Af014b21BdA52a8379E",
  18,
  "wLYXt",
  "Wrapped LYXt"
);

export const UNI: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    UNI_ADDRESSES[ChainId.MAINNET],
    18,
    "UNI",
    "Uniswap"
  ),
};

export const LDO = new Token(
  ChainId.MAINNET,
  "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
  18,
  "LDO",
  "Lido DAO Token"
);
export const NMR = new Token(
  ChainId.MAINNET,
  "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671",
  18,
  "NMR",
  "Numeraire"
);
export const MNW = new Token(
  ChainId.MAINNET,
  "0xd3E4Ba569045546D09CF021ECC5dFe42b1d7f6E4",
  18,
  "MNW",
  "Morpheus.Network"
);

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } =
  {
    ...(WETH9 as Record<ChainId, Token>),
    [CustomChainId.LUKSO_MAINNET]: WLYX_LUKSO_MAINNET,
    [CustomChainId.LUKSO_TESTNET]: WLYX_LUKSO_TESTNET,
  };

export function isLukso(
  chainId: number
): chainId is CustomChainId.LUKSO_MAINNET {
  return chainId === CustomChainId.LUKSO_MAINNET;
}

class LuksoNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }

  get wrapped(): Token {
    // TODO REVIEW THIS BECAUSE OF EXTENDED ETHER
    // if (!isLukso(this.chainId)) throw new Error("Not Lukso");
    const wrapped = WRAPPED_NATIVE_CURRENCY[42];
    invariant(wrapped instanceof Token);
    return wrapped;
  }

  public constructor(chainId: number) {
    // if (!isLukso(chainId)) throw new Error("Not Lukso");

    super(chainId, 18, "LYX", "LYX");
  }
}

export function isLuksoTestnet(
  chainId: number
): chainId is CustomChainId.LUKSO_TESTNET {
  return chainId === CustomChainId.LUKSO_TESTNET;
}

class LuksoNativeTestnetCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }

  get wrapped(): Token {
    if (!isLuksoTestnet(this.chainId)) throw new Error("Not Lukso Testnet");
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    invariant(wrapped instanceof Token);
    return wrapped;
  }

  public constructor(chainId: number) {
    if (!isLuksoTestnet(chainId)) throw new Error("Not Lukso Testnet");

    super(chainId, 18, "LYXt", "LYXt");
  }
}

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
    if (wrapped) {
      return wrapped;
    } else {
      return WRAPPED_NATIVE_CURRENCY[42] as Token;
    }
    throw new Error(`Unsupported chain ID: ${this.chainId}`);
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } =
    {};

  public static onChain(chainId: number): ExtendedEther {
    return (
      this._cachedExtendedEther[chainId] ??
      (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
    );
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {};
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId];
  let nativeCurrency: NativeCurrency | Token;
  if (isLukso(chainId)) {
    nativeCurrency = new LuksoNativeCurrency(chainId);
  }
  if (isLuksoTestnet(chainId)) {
    nativeCurrency = new LuksoNativeTestnetCurrency(chainId);
  } else {
    // nativeCurrency = ExtendedEther.onChain(chainId);
    nativeCurrency = new LuksoNativeCurrency(chainId);
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency);
}

export function getSwapCurrencyId(currency: Currency): string {
  if (currency.isToken) {
    return currency.address;
  }
  return NATIVE_CHAIN_ID;
}

export const TOKEN_SHORTHANDS: {
  [shorthand: string]: { [chainId in ChainId | CustomChainId]?: string };
} = {
  USDC: {
    [ChainId.MAINNET]: USDC_MAINNET.address,
    [CustomChainId.LUKSO_MAINNET]:
      USDC_LUKSO[CustomChainId.LUKSO_MAINNET].address,
    [CustomChainId.LUKSO_TESTNET]:
      USDC_LUKSO[CustomChainId.LUKSO_TESTNET].address,
  },
};
