import { ChainId } from "@uniswap/sdk-core";
import { CustomChainId } from "constants/chains";

const BLOCK_EXPLORER_PREFIXES: { [chainId: number]: string } = {
  [ChainId.MAINNET]: "https://etherscan.io",
  [CustomChainId.LUKSO_MAINNET]:
    "https://explorer.execution.mainnet.lukso.network",
  [CustomChainId.LUKSO_TESTNET]:
    "https://explorer.execution.testnet.lukso.network",
};

export enum ExplorerDataType {
  TRANSACTION = "transaction",
  TOKEN = "token",
  ADDRESS = "address",
  BLOCK = "block",
  NATIVE = "native",
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(
  chainId: number,
  data: string,
  type: ExplorerDataType
): string {
  const prefix = BLOCK_EXPLORER_PREFIXES[chainId] ?? "https://etherscan.io";

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`;

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`;

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`;

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`;
    default:
      return `${prefix}`;
  }
}
