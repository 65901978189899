// eslint-disable-next-line no-restricted-imports
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfaceSectionName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from "analytics";
import clsx from "clsx";
import { Search } from "components/Icons/Search";
import { useCollectionSearch } from "graphql/data/nft/CollectionSearch";
import { useSearchTokens } from "graphql/data/SearchTokens";
import useDebounce from "hooks/useDebounce";
import { useDisableNFTRoutes } from "hooks/useDisableNFTRoutes";
import { useIsNftPage } from "hooks/useIsNftPage";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { organizeSearchResults } from "lib/utils/searchBar";
import { Box } from "nft/components/Box";
import { Column, Row } from "nft/components/Flex";
import { magicalGradientOnHover } from "nft/css/common.css";
import { useIsMobile, useIsTablet } from "nft/hooks";
import { useIsNavSearchInputVisible } from "nft/hooks/useIsNavSearchInputVisible";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { NavMagnifyingGlassIcon } from "../../nft/components/icons";
import { NavIcon } from "./NavIcon";
import * as styles from "./SearchBar.css";
import { SearchBarDropdown, UsersCollection } from "./SearchBarDropdown";
import { DoubleProfilePicture } from "pages/Social/components/User/DoubleProfilePicture";
import { GetUsersByName } from "pages/Social/utils/TradeFilterService";
import { useLYXPriceRatio } from "hooks/useLYXPriceRatio";

const KeyShortCut = styled.div`
  background-color: ${({ theme }) => theme.surface3};
  color: ${({ theme }) => theme.neutral2};
  padding: 0px 8px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 535;
  line-height: 16px;
  display: flex;
  align-items: center;
  opacity: 0.6;
  backdrop-filter: blur(60px);
`;
export interface UserSearchResultProps {
  name: string;
  id: string;
  profilePic: string;
  identicon: string;
}

export const SearchBar = () => {
  const [isOpen, toggleOpen] = useReducer((state: boolean) => !state, false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isNavSearchInputVisible = useIsNavSearchInputVisible();
  const shouldDisableNFTRoutes = useDisableNFTRoutes();

  useOnClickOutside(searchRef, () => {
    isOpen && toggleOpen();
  });

  const { data: collections, loading: collectionsAreLoading } =
    useCollectionSearch(debouncedSearchValue);

  const { chainId } = useWeb3React();
  const ratio = useLYXPriceRatio();

  const { data: tokens, loading: tokensAreLoading } = useSearchTokens(
    debouncedSearchValue,
    ratio
  );

  const [reducedUsers, setReducedUsers] = useState<UsersCollection[]>([]);

  useEffect(() => {
    async function searchUsers(query: string) {
      const users = await GetUsersByName(query);
      const formatted = users.map((user: UserSearchResultProps) => {
        return {
          name: user.name,
          id: user.id,
          address: user.id,
          chain: chainId,
          profilePic: DoubleProfilePicture({
            primary: {
              size: 30,
              src: user.profilePic,
            },
            secondary: {
              size: 15,
              src: user.identicon,
            },
            position: "relative",
          }),
        };
      });
      setReducedUsers(formatted);
    }
    searchUsers(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const isNFTPage = useIsNftPage();

  const [reducedTokens, reducedCollections] = organizeSearchResults(
    isNFTPage,
    tokens ?? [],
    collections ?? []
  );

  // close dropdown on escape
  useEffect(() => {
    const escapeKeyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        event.preventDefault();
        toggleOpen();
      }
    };

    document.addEventListener("keydown", escapeKeyDownHandler);

    return () => {
      document.removeEventListener("keydown", escapeKeyDownHandler);
    };
  }, [isOpen, toggleOpen, collections]);

  // clear searchbar when changing pages
  useEffect(() => {
    setSearchValue("");
  }, [pathname]);

  // auto set cursor when searchbar is opened
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const isMobileOrTablet = isMobile || isTablet || !isNavSearchInputVisible;

  const trace = useTrace({ section: InterfaceSectionName.NAVBAR_SEARCH });

  const navbarSearchEventProperties = {
    navbar_search_input_text: debouncedSearchValue,
    hasInput: debouncedSearchValue && debouncedSearchValue.length > 0,
    ...trace,
  };

  const { i18n } = useLingui(); // subscribe to locale changes
  const placeholderText = isMobileOrTablet
    ? t(i18n)`Search`
    : shouldDisableNFTRoutes
    ? t(i18n)`Search tokens and users`
    : t(i18n)`Search tokens and NFT collections`;

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "/") {
        event.preventDefault();
        !isOpen && toggleOpen();
      }
    },
    [isOpen]
  );

  useEffect(() => {
    const innerRef = inputRef.current;

    if (innerRef !== null) {
      //only mount the listener when input available as ref
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (innerRef !== null) {
        document.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, [handleKeyPress, inputRef]);

  return (
    <Trace section={InterfaceSectionName.NAVBAR_SEARCH}>
      <Column
        data-cy="search-bar"
        position={{ sm: "fixed", md: "absolute" }}
        width={{ sm: isOpen ? "viewWidth" : "auto", md: "auto" }}
        ref={searchRef}
        className={clsx(styles.searchBarContainerNft, {
          searchBarContainerDisableBlur: isNavSearchInputVisible,
        })}
        display={{ sm: isOpen ? "flex" : "none", xl: "flex" }}
        {...(isNavSearchInputVisible && {
          position: "relative",
          display: "flex",
        })}
        {...(isOpen && {
          boxShadow: "deep",
        })}
      >
        <Row
          className={clsx(
            styles.nftSearchBar,
            !isOpen && !isMobile && magicalGradientOnHover,
            isMobileOrTablet && (isOpen ? styles.visible : styles.hidden)
          )}
          borderRadius={isOpen || isMobileOrTablet ? undefined : "16"}
          borderTopRightRadius={isOpen && !isMobile ? "16" : undefined}
          borderTopLeftRadius={isOpen && !isMobile ? "16" : undefined}
          borderBottomWidth={isOpen || isMobileOrTablet ? "0px" : "1px"}
          backgroundColor={isOpen ? "surface1" : "surface1"}
          onClick={() => !isOpen && toggleOpen()}
          gap="12"
        >
          {/* <Box className={styles.searchContentLeftAlign}>
            <Box display={{ sm: 'none', md: 'flex' }}>
              <Search width="20px" height="20px" />
            </Box>
            <Box display={{ sm: 'flex', md: 'none' }} color="neutral3" onClick={toggleOpen}>
              <ChevronLeftIcon />
            </Box>
          </Box> */}
          <TraceEvent
            events={[BrowserEvent.onFocus]}
            name={InterfaceEventName.NAVBAR_SEARCH_SELECTED}
            element={InterfaceElementName.NAVBAR_SEARCH_INPUT}
            properties={{ ...trace }}
          >
            <Box
              as="input"
              data-cy="search-bar-input"
              placeholder={placeholderText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                !isOpen && toggleOpen();
                setSearchValue(event.target.value);
              }}
              onBlur={() =>
                sendAnalyticsEvent(
                  InterfaceEventName.NAVBAR_SEARCH_EXITED,
                  navbarSearchEventProperties
                )
              }
              className={`${styles.searchBarInput} ${styles.searchContentLeftAlign}`}
              value={searchValue}
              ref={inputRef}
              width="full"
            />
          </TraceEvent>
          {!isOpen && <KeyShortCut>/</KeyShortCut>}
        </Row>
        <Column
          overflow="hidden"
          className={clsx(isOpen ? styles.visible : styles.hidden)}
        >
          {isOpen && (
            <SearchBarDropdown
              toggleOpen={toggleOpen}
              users={reducedUsers}
              tokens={reducedTokens}
              collections={reducedCollections}
              queryText={debouncedSearchValue}
              hasInput={debouncedSearchValue.length > 0}
              isLoading={tokensAreLoading || collectionsAreLoading}
            />
          )}
        </Column>
      </Column>
      {isMobileOrTablet && (
        <NavIcon onClick={toggleOpen} label={placeholderText}>
          <NavMagnifyingGlassIcon />
        </NavIcon>
      )}
    </Trace>
  );
};
