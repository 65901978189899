import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { NEVER_RELOAD, useSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";
import { useUniversalProfileDetectorContract } from "./useContract";

export default function useIsUniversalProfile(account: string): boolean {
  const lsp0InterfaceId = ethers.utils.hexlify("0x24871b3d");

  const universalProfileDetector = useUniversalProfileDetectorContract(account);

  const call = useSingleCallResult(
    universalProfileDetector,
    "supportsInterface",
    [lsp0InterfaceId],
    NEVER_RELOAD
  );
  return Boolean(call?.result?.[0]);
  return true;
}
