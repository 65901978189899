import { CircleLogoImage } from "components/CircleLogoImage";
import { Box } from "nft/components/Box";
import tokenDefault from "assets/svg/token-default.svg";
import useImageUrl from "hooks/useImageUrl";
import { useState } from "react";

const ImagePrimary = function ({ src, size, onClick }: ProfilePictureProps) {
  return (
    <CircleLogoImage
      src={src}
      size={`${size}px`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  );
};

const ImageSecondary = function ({ src, size }: ProfilePictureProps) {
  return (
    <Box position="absolute" bottom="0" right="0">
      <CircleLogoImage src={src} size={`${size}px`} />
    </Box>
  );
};

export interface ProfilePictureProps {
  src: string;
  size: number;
  onClick?: () => void;
}

export interface DoubleProfilePictureProps {
  primary: ProfilePictureProps;
  secondary: ProfilePictureProps;
  position: "absolute" | "fixed" | "relative" | "sticky" | "static";
  onClick?: () => void;
}

export const DoubleProfilePicture = function (
  props: DoubleProfilePictureProps
) {
  return (
    <Box
      onClick={props.onClick}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position={props.position}
      padding="4"
      gap="10"
      bottom={props.position === "absolute" ? "0" : "auto"}
    >
      <ImagePrimary src={props.primary.src} size={props.primary.size} />
      <ImageSecondary src={props.secondary.src} size={props.secondary.size} />
    </Box>
  );
};
