// Gets transactions by user address (id)

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { ITransactionResult } from "./QueryInterfaces";
import { USER_TRANSACTIONS_PER_QUERY } from "../utils/Contants";

const userTransactionsClient = new ApolloClient({
    uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions", // Replace with your desired API URL
    cache: new InMemoryCache(),
});

const UserTransactionsDocument = gql`
  query UserTransactionsQuery($id: ID!, $first: Int, $skip: Int) {
    transactions(
      first: $first
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
      where: {or: [ 
        { or: [ 
            { burns_: {origin: $id}}, 
            {mints_: {origin: $id}},
        ]},
        {swaps_: {origin: $id}},
      ]}
    ) {
      burns {
        id
        amount0
        amount1
        amountUSD
        origin
        timestamp
        token0 {
          id
          name
          symbol
        }
        token1 {
          id
          name
          symbol
        }
      }
      mints {
        amount
        origin
        timestamp
        token0 {
          id
          name
          symbol
        }
        token1 {
          id
          name
          symbol
        }
        amount0
        amount1
      }
      swaps {
        id
        amount0
        amount1
        amountUSD
        origin
        timestamp
        token0 {
          id
          name
          symbol
        }
        token1 {
          id
          name
          symbol
        }
      }
      timestamp
    }
  }
`;

export interface GetUserTransactionsQueryParams {
  id: string
  first?: number
  skip?: number
}

export async function fetchUserTransactions(queryParams: GetUserTransactionsQueryParams) : Promise<ITransactionResult[]> {
  const id = queryParams.id.toLowerCase();
  const first = queryParams.first ?? USER_TRANSACTIONS_PER_QUERY;
  const skip = queryParams.skip ?? 0;
  
  const { data, error, loading } = await userTransactionsClient.query({
    variables: { id, first, skip },
    query: UserTransactionsDocument,
    fetchPolicy: 'cache-first',
  })

  return data?.transactions ?? [];
};
