import { InterfaceElementName } from "@uniswap/analytics-events";
import { DollarSign, Terminal } from "react-feather";
import styled from "styled-components";
import { lightTheme } from "theme/colors";

import darkArrowImgSrc from "./images/aboutArrowDark.png";
import lightArrowImgSrc from "./images/aboutArrowLight.png";
import darkDollarImgSrc from "./images/aboutDollarDark.png";
import darkTerminalImgSrc from "./images/aboutTerminalDark.png";
import nftCardImgSrc from "./images/nftCard.png";
import swapCardImgSrc from "./images/swapCard.png";

export const MAIN_CARDS = [
  {
    to: "/swap",
    title: "Swap tokens",
    description: "Buy, sell, and explore tokens built on the LUKSO Network.",
    cta: "Trade Tokens",
    darkBackgroundImgSrc: swapCardImgSrc,
    lightBackgroundImgSrc: swapCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_SWAP_CARD,
  },
  {
    to: "/nfts",
    title: "Trade NFTs",
    description:
      "Buy and sell NFTs across marketplaces to find more listings at better prices.",
    cta: "Explore NFTs",
    darkBackgroundImgSrc: nftCardImgSrc,
    lightBackgroundImgSrc: nftCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_NFTS_CARD,
  },
];

const StyledCardLogo = styled.img`
  min-width: 20px;
  min-height: 20px;
  max-height: 48px;
  max-width: 48px;
`;

export const ROADMAP_CARDS_LINE1 = [
  {
    title: "Stage 1",
    description: (
      <ul>
        <li>
          Launch of Testnet <span style={{ color: "green" }}> ✔ Done</span>
        </li>
        <li>
          Audit Finalization <span style={{ color: "green" }}> ✔ Done</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Stage 2",
    description: (
      <ul>
        <li>
          Report Bugs and Apply Fixes{" "}
          <span style={{ color: "green" }}> ✔ Done</span>
        </li>
        <li>
          Open Source the code <span style={{ color: "green" }}> ✔ Done</span>
        </li>
        <li>
          Launch on Mainnet <span style={{ color: "green" }}> ✔ Done</span>
        </li>
      </ul>
    ),
  },
];

export const ROADMAP_CARDS_LINE2 = [
  {
    title: "Stage 3",
    description: (
      <ul>
        <li>
          Add social page <span>⌛️ End of Q1</span>
        </li>

        <li>
          Re-Design the interface <span>⌛️ End of Q1</span>
        </li>
        <li>
          Add an activity page <span>⌛️ End of Q1</span>
        </li>
        <li>
          Add Developer Documentation <span>⌛️ End of Q1</span>
        </li>
      </ul>
    ),
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    title: "Stage 4",
    description: (
      <ul>
        <li>
          Launch the protocol token <span>⌛️ Mid of Q2</span>
        </li>
        <li>
          Setup governance infra <span>⌛️ Mid of Q2</span>
        </li>
        <li>
          Move ownership to DAO <span>⌛️ End of Q2</span>
        </li>
        <li>
          Research DeFi Future on LUKSO <span>⌛️ Q3</span>
        </li>
      </ul>
    ),
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
];

export const MORE_CARDS = [
  {
    to: '/social',
    external: true,
    title: "Social Activity",
    description:
      "Explore recent swaps done by users and track their exchange activities.",
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Explore" />,
    cta: "Social",
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    to: "/pools",
    title: "Earn",
    description:
      "Provide liquidity to pools on UniversalSwaps and earn fees on swaps.",
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: "Provide liquidity",
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    // to: '#',
    external: true,
    title: "Build dApps",
    description: "Build apps and tools on the largest DeFi protocol on LUKSO.",
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: "Dev docs - Coming Soon",
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
];
