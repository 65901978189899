import { TradeTypeEnum } from "./TradeEnum";
import bigUnicorn from "assets/images/big_unicorn.png";
import imgEth from "assets/images/ethereum-logo.png";
import gnosisImg from "assets/images/gnosis.png";

interface TokenInterface {
    name: string
    symbol: string
    address: string
    logoUrl: string
}

interface TradeDataInterface {
    tokenAddress: string;
    qty: number;
}

interface UserPictureInterface {
    main: string,
    cover: string,
    identicon: string
}

export interface TradeMockInterface {
    userAddr: string;
    name: string;
    from: TradeDataInterface;
    to: TradeDataInterface;
    type: TradeTypeEnum;
    time: string;
}

export interface UserMockInterface {
    name: string;
    address: string;
    picture: UserPictureInterface;
    tags: string[];
    description: string;
}

export interface FollowInterface {
    followerAddress: string;
    followedAddress: string;
}


export const FOLLOWED_USERS = [
    "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C"
];

export const FOLLOWS = [
    // Betty follows Toto (4 followers, 1 followed)
    {
        followerAddress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    // Karl follows betty and serena (3 followers, 2 followed)
    {
        followerAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    {
        followerAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    // Toto follows everyone (1 follower, 2 followed)
    {
        followerAddress: "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    {
        followerAddress: "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    {
        followerAddress: "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    // Serena follows betty and karl (3 followers, 2 followed)
    {
        followerAddress: "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
    {
        followerAddress: "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        followedAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
    },
]

export interface SocialUserPicturesInterface {
    main: string,
    cover: string,
    identicon: string,
};

export interface ITag {
    display: string
    target: string
}

export interface SocialUserInterface {
    name: string,
    address: string,
    picture: SocialUserPicturesInterface,
    tags: ITag[],
    description: string
}

export const USERS = [
    {
        name: "@moi",
        address: "0x0aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        picture: {main: imgEth, cover: bigUnicorn, identicon: gnosisImg },
        tags: ['Traveler', 'Designer', 'Dog Lover'],
        description: "Cool"
    },
    {
        name: "@betty",
        address: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        picture: {main: imgEth, cover: bigUnicorn, identicon: gnosisImg },
        tags: ['Traveler', 'Designer', 'Dog Lover'],
        description: "I'm Betty and I love swapping tokens on Universal Swaps all day !"
    },
    {
        name: "@karl-hungus",
        address: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        picture: {main: imgEth, cover: bigUnicorn, identicon: gnosisImg },
        tags: ['Nerd', 'Architect', 'Cat Lover'],
        description: "I'm Karl and I love swapping tokens on Universal Swaps all day !"
    },
    {
        name: "@toto",
        address: "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        picture: {main: imgEth, cover: bigUnicorn, identicon: gnosisImg },
        tags: ['Books', 'Tech', 'Horse Lover'],
        description: "I'm Toto and I love swapping tokens on Universal Swaps all day !"
    },
    {
        name: "@serena",
        address: "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        picture: {main: imgEth, cover: bigUnicorn, identicon: gnosisImg },
        tags: ['Travel', 'Chain', 'Tech'],
        description: "I'm Serena and I love swapping tokens on Universal Swaps all day !"
    }
]

export const TOKENS = [
    {
        name: 'CHILL',
        symbol: 'CHILL',
        address: '0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14',
        logoUrl: imgEth
    },
    {
        name: 'Wrapped LYX',
        symbol: 'wLYX',
        address: '0x2db41674f2b882889e5e1bd09a3f3613952bc472',
        logoUrl: gnosisImg
    },
]

export const TRADES = [
    {
        amountToken0 : 100,
        amountToken1 : -130,
        amountUSD : 5,
        hash : "0xf297c73d1bbe4e16ef73db9098b60250cced2e367f6326033c455bc519b7d4ab",
        metadata : "0x00008019f9b10020f2912f15395a3c06498bd5d424f4caabebd99b3119c20b3910535e9f619603a0697066733a2f2f6261666b726569686876676378756c326f746e667468753563706a687970736a346b376d737a766837326d37793672737a7a666a6a6c7079366569",
        sender : "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C", // betty
        timestamp : "1709667804",
        token0Address : "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // chill
        token0Symbol : "CHILL",
        token1Address : "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // lyx
        token1Symbol : "WLYX",
        type : 0 // swap
    },
    {
        amountToken0 : 50,
        amountToken1 : -120,
        amountUSD : 12,
        hash : "0xf297c73d1bbe4e16ef73db9098b60250cced2e367f6326033c455bc519b7d4ab",
        metadata : "0x00008019f9b10020f2912f15395a3c06498bd5d424f4caabebd99b3119c20b3910535e9f619603a0697066733a2f2f6261666b726569686876676378756c326f746e667468753563706a687970736a346b376d737a766837326d37793672737a7a666a6a6c7079366569",
        sender : "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C", // karl
        timestamp : "1709665802",
        token0Address : "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // lyx
        token0Symbol : "LYX",
        token1Address : "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // chill
        token1Symbol : "CHILL",
        type : 1 // deposit
    },
    {
        amountToken0 : 200,
        amountToken1 : -260,
        amountUSD : 25,
        hash : "0xf297c73d1bbe4e16ef73db9098b60250cced2e367f6326033c455bc519b7d4ab",
        metadata : "0x00008019f9b10020f2912f15395a3c06498bd5d424f4caabebd99b3119c20b3910535e9f619603a0697066733a2f2f6261666b726569686876676378756c326f746e667468753563706a687970736a346b376d737a766837326d37793672737a7a666a6a6c7079366569",
        sender : "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C", // betty
        timestamp : "1709567809",
        token0Address : "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // chill
        token0Symbol : "CHILL",
        token1Address : "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // lyx
        token1Symbol : "WLYX",
        type : 2 // withdrawal
    },
    {
        amountToken0 : 200,
        amountToken1 : -260,
        amountUSD : 25,
        hash : "0xf297c73d1bbe4e16ef73db9098b60250cced2e367f6326033c455bc519b7d4ab",
        metadata : "0x00008019f9b10020f2912f15395a3c06498bd5d424f4caabebd99b3119c20b3910535e9f619603a0697066733a2f2f6261666b726569686876676378756c326f746e667468753563706a687970736a346b376d737a766837326d37793672737a7a666a6a6c7079366569",
        sender : "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C", // toto
        timestamp : "1709267801",
        token0Address : "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // chill
        token0Symbol : "CHILL",
        token1Address : "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // lyx
        token1Symbol : "WLYX",
        type : 2 // withdrawal
    },
    {
        amountToken0 : 500,
        amountToken1 : -1200,
        amountUSD : 12,
        hash : "0xf297c73d1bbe4e16ef73db9098b60250cced2e367f6326033c455bc519b7d4ab",
        metadata : "0x00008019f9b10020f2912f15395a3c06498bd5d424f4caabebd99b3119c20b3910535e9f619603a0697066733a2f2f6261666b726569686876676378756c326f746e667468753563706a687970736a346b376d737a766837326d37793672737a7a666a6a6c7079366569",
        sender : "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C", // betty
        timestamp : "1709365802",
        token0Address : "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // lyx
        token0Symbol : "LYX",
        token1Address : "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // chill
        token1Symbol : "CHILL",
        type : 0 // deposit
    },
    {
        amountToken0 : 10,
        amountToken1 : -120,
        amountUSD : 1,
        hash : "0xf297c73d1bbe4e16ef73db9098b60250cced2e367f6326033c455bc519b7d4ab",
        metadata : "0x00008019f9b10020f2912f15395a3c06498bd5d424f4caabebd99b3119c20b3910535e9f619603a0697066733a2f2f6261666b726569686876676378756c326f746e667468753563706a687970736a346b376d737a766837326d37793672737a7a666a6a6c7079366569",
        sender : "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C", // serena
        timestamp : "1708065802",
        token0Address : "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // lyx
        token0Symbol : "LYX",
        token1Address : "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // chill
        token1Symbol : "CHILL",
        type : 1 // swap
    },
];

export const PORTFOLIOS = [
    {   // Betty owns 1534 CHILLs
        userAddress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        tokenAddress: "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14",
        quantity: 1534
    },
    {   // Betty owns 38765 LYXs
        userAddress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        tokenAddress: "0x2db41674f2b882889e5e1bd09a3f3613952bc472",
        quantity: 38765
    },
    {   // Karl owns 132 CHILLs
        userAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        tokenAddress: "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14",
        quantity: 132
    },
    {   // Karl owns 689 LYXs
        userAddress: "0x2aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        tokenAddress: "0x2db41674f2b882889e5e1bd09a3f3613952bc472",
        quantity: 689
    },
    {   // Toto owns 80200 CHILLs
        userAddress: "0x3aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        tokenAddress: "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14",
        quantity: 80200
    },
    {   // Serena owns 7654321 LYXs
        userAddress: "0x4aAe0E0ffc131655fE1E66294a90fa22B443C48C",
        tokenAddress: "0x2db41674f2b882889e5e1bd09a3f3613952bc472",
        quantity: 7654321
    }
]

export const POOLS = [
    {
        userAdress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C", // Betty
        token1: "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // Chill
        token2: "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // Lyx
        liquidity: 148.07,
        inRange: true,
        rate: 0.3
    },
    {
        userAdress: "0x1aAe0E0ffc131655fE1E66294a90fa22B443C48C", // Betty
        token1: "0x2db41674f2b882889e5e1bd09a3f3613952bc472", // Lyx
        token2: "0x5b8b0e44d4719f8a328470dccd3746bfc73d6b14", // Chill
        liquidity: 259.42,
        inRange: false,
        rate: 0.7
    }
]
