// Gets user full profile
// - address (id)
// - name
// - description
// - links
// - profile picture
// - identicon
// - cover picture

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UserProfileDocument = gql`
  query GetUserProfile($_id: ID!) {
    profile(id: $_id) {
      description
      id
      links {
        title
        url
      }
      name
      profileImages {
        url
      }
      backgroundImages {
        url
      }
    }
  }
`;

const userProfileClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile-backup", // Replace with your desired API URL
  cache: new InMemoryCache(),
});

export async function fetchUserProfile(id: string) {
  const _id = id.toLowerCase();
  const { data, error, loading } = await userProfileClient.query({
    variables: { _id },
    query: UserProfileDocument,
    fetchPolicy: "cache-first",
  });
  return data;
}
