async function getImagesURLs(
  urls: string[],
  fallbackUrl = ""
): Promise<string[]> {
  // const validUrls = await Promise.all(
  //   urls.map(async (url) => {
  //     try {
  //       const response = await fetch(url);
  //       return response.ok ? url : fallbackUrl;
  //     } catch (error) {
  //       return fallbackUrl;
  //     }
  //   })
  // );
  // return validUrls;
  return new Promise((resolve, reject) => resolve(urls))
}

export default getImagesURLs;
