import jazzicon from "@metamask/jazzicon";
import useENSAvatar from "hooks/useENSAvatar";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import makeBlockie from "ethereum-blockies-base64";
import LSP3ProfileSchema from "@erc725/erc725.js/schemas/LSP3ProfileMetadata.json";
import ERC725 from "@erc725/erc725.js";
import { ERC725YDataKeys } from "@lukso/lsp-smart-contracts";
import { ipfsUrlToGatewayUrl } from "constants/luksoUtils";
import { useWeb3React } from "@web3-react/core";
import { RPC_URLS } from "constants/networks";
import { CustomChainId } from "constants/chains";

const StyledIdenticon = styled.div<{ iconSize: number }>`
  height: ${({ iconSize }) => `${iconSize}px`};
  width: ${({ iconSize }) => `${iconSize}px`};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.surface3};
  font-size: initial;
`;

const StyledAvatar = styled.img`
  height: inherit;
  width: inherit;
  border-radius: inherit;
`;

interface Verification {
  method: "keccak256(bytes)" | "ecdsa";
  data: string;
  source?: string;
}

interface AvatarOrImage {
  verification?: Verification;
  url: string;
  fileType?: string;
  width?: number;
  height?: number;
  address?: string;
  tokenId?: string; // Assuming "32bytes" is a placeholder for a string type
}

interface LSP3Profile {
  name: string;
  description: string;
  links: Array<{
    title: string;
    url: string;
  }>;
  tags: string[];
  avatar: AvatarOrImage[];
  profileImage: AvatarOrImage[];
  backgroundImage: AvatarOrImage[];
}

interface ERC725YMetadata {
  value: {
    LSP3Profile: LSP3Profile;
  };
}

export default function UniversalProfileIdenticon({
  account,
  size,
}: {
  url?: string;
  account?: string;
  size?: number;
}) {
  const [url, setUrl] = useState<string>("");
  const [fetchable, setFetchable] = useState(true);

  const iconSize = size ?? 24;

  const { chainId } = useWeb3React();

  const fetchUniversalProfilePicture = async (
    account: string,
    chainId: CustomChainId
  ) => {
    const ipfsConfig = {
      ipfsGateway: "https://api.universalprofile.cloud/ipfs",
    };

    // if (!chainId) return

    // const rpcUrl = RPC_PROVIDERS[chainId as 42 | 4201];
    const rpcUrl = RPC_URLS[chainId as 42 | 4201][0];

    const erc725js = new ERC725(
      LSP3ProfileSchema,
      account,
      rpcUrl, // Assuming this is correctly pointing to your desired RPC URL.
      ipfsConfig
    );

    try {
      const metadata: ERC725YMetadata = await erc725js.fetchData(
        ERC725YDataKeys.LSP3.LSP3Profile
      );

      if (metadata?.value?.LSP3Profile?.profileImage?.[0]?.url) {
        let urlMetadata = metadata.value.LSP3Profile.profileImage[0].url;
        if (urlMetadata.startsWith("ipfs://")) {
          urlMetadata = ipfsUrlToGatewayUrl(urlMetadata);
        }

        setUrl(urlMetadata);
      }
    } catch (error) {
      if (!error.message.includes("Value of key")) {
        console.error("Error fetching universal profile picture", error);
      }
    }
  };

  useEffect(() => {
    if (account && chainId) {
      fetchUniversalProfilePicture(account, chainId);
    }
  }, [chainId, account]);

  // useMemo for blockie creation to avoid unnecessary recalculations
  const blockie = useMemo(
    () => (account ? makeBlockie(account) : ""),
    [account]
  );
  const handleError = useCallback(() => setFetchable(false), []);

  return (
    <StyledIdenticon iconSize={iconSize}>
      {url != "" ? (
        <StyledAvatar
          alt="avatar"
          src={url}
          onError={handleError}
        ></StyledAvatar>
      ) : (
        <StyledAvatar
          alt="blockie"
          src={blockie}
          onError={handleError}
        ></StyledAvatar>
      )}
    </StyledIdenticon>
  );
}
