import React from "react";
import { IconPaths, Icons } from "./UniconSVGs";
import {
  getUniconV2Colors,
  getUniconsV2DeterministicHash,
  isValidEthAddress,
} from "./utils";

const styles = {
  transformOrigin: "center center",
};

export const generateBlockie = (address: string, size: number): string => {
  if (!address || !isValidEthAddress(address)) {
    return "";
  }

  const hashValue = getUniconsV2DeterministicHash(address);
  const { color } = getUniconV2Colors(address, false); // Assuming light mode for now

  const iconKeys = Object.keys(Icons);
  if (iconKeys.length === 0) {
    throw new Error("No icons available");
  }

  const iconIndex = Math.abs(Number(hashValue)) % iconKeys.length;
  const selectedIconKey = iconKeys[iconIndex] as keyof typeof Icons;
  const selectedIconPaths: IconPaths | undefined = Icons[selectedIconKey];

  if (!selectedIconPaths) {
    throw new Error(`No icon found for key: ${String(selectedIconKey)}`);
  }

  const ORIGINAL_CONTAINER_SIZE = 48;
  const scaleValue = size / ORIGINAL_CONTAINER_SIZE / 1.5;
  const scaledSVGSize = ORIGINAL_CONTAINER_SIZE * scaleValue;
  const translateX = (size - scaledSVGSize) / 2;
  const translateY = (size - scaledSVGSize) / 2;

  const svgXml = `
    <svg height="${size}" viewBox="0 0 ${size} ${size}" width="${size}" xmlns="http://www.w3.org/2000/svg">
      <g style="transform-origin: center center">
        <circle cx="${size / 2}" cy="${size / 2}" fill="${color}1F" r="${
    size / 2
  }"/>
        <g transform="translate(${translateX}, ${translateY}) scale(${scaleValue})">
          ${selectedIconPaths
            .map(
              (pathData: string) =>
                `<path clip-rule="evenodd" d="${pathData}" fill="${color}" fill-rule="evenodd"/>`
            )
            .join("")}
        </g>
      </g>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svgXml)}`;
};
