// Gets all transactions
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { ITransactionResult } from "./QueryInterfaces";

const LatestTransactionsDocument = gql`
  query GetLatestTransactions($timestamp: Int, $followees: [ID]) {
    transactions(
        where: { and: [
            {timestamp_gt: $timestamp},
            {or: [{burns_: {origin_in: $followees}}, {mints_: {origin_in: $followees}}, {swaps_: {origin_in: $followees}}]}
        ]}
    ) {
      burns {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          symbol
          id
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      mints {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      swaps {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          symbol
          id
        }
        timestamp
      }
      timestamp
    }
  }
`;


const latestTransactionsClient = new ApolloClient({
    uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions", // Replace with your desired API URL
    cache: new InMemoryCache(),
});

export interface GetFolloweesTradesSinceTimestampQueryParams {
  timestamp: number,
  followees: string[]
}

export async function fetchFolloweesTransactionsSinceTimeStamp(
    queryParams: GetFolloweesTradesSinceTimestampQueryParams
) : Promise<ITransactionResult[]> {
    const timestamp = queryParams.timestamp;
    const followees = queryParams.followees;

    const { data, error, loading } = await latestTransactionsClient.query({
        query: LatestTransactionsDocument,
        variables: { timestamp, followees },
        fetchPolicy: 'no-cache',
    })

    if (!error && !loading) {
        return data.transactions;
    }
    return [];
}
