import { ChainId } from "@uniswap/sdk-core";
import ms from "ms";
import { darkTheme } from "theme/colors";

import { CustomChainId, SupportedL1ChainId } from "./chains";

export const AVERAGE_L1_BLOCK_TIME = ms(`12s`);

// The block number at which v3 was deployed on each chain, separate from the UNIVERSAL_ROUTER_CREATION_BLOCK
export const START_BLOCKS: { [key: number]: number } = {
  [ChainId.MAINNET]: 14292820,
  [CustomChainId.LUKSO_MAINNET]: 1709557,
  [CustomChainId.LUKSO_TESTNET]: 2027279,
};

export enum NetworkType {
  L1,
}
interface BaseChainInfo {
  readonly networkType: NetworkType;
  readonly blockWaitMsBeforeWarning?: number;
  readonly docs: string;
  readonly bridge?: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly label: string;
  readonly helpCenterUrl?: string;
  readonly nativeCurrency: {
    name: string; // e.g. 'Goerli ETH',
    symbol: string; // e.g. 'gorETH',
    decimals: number; // e.g. 18,
  };
  readonly color?: string;
  readonly backgroundColor?: string;
}

interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1;
  readonly defaultListUrl?: string;
}

type ChainInfoMap = {
  readonly [chainId: number]: L1ChainInfo;
} & { readonly [chainId in SupportedL1ChainId]: L1ChainInfo };

const CHAIN_INFO: ChainInfoMap = {
  [ChainId.MAINNET]: {
    networkType: NetworkType.L1,
    docs: "https://docs.uniswap.org/",
    explorer: "https://etherscan.io/",
    infoLink: "https://info.uniswap.org/#/",
    label: "LUKSO",
    nativeCurrency: { name: "LUKSO", symbol: "LYX", decimals: 18 },
    color: darkTheme.chain_43114,
  },
  [CustomChainId.LUKSO_MAINNET]: {
    networkType: NetworkType.L1,
    docs: "https://docs.lukso.tech/",
    explorer: "https://explorer.execution.mainnet.lukso.network",
    infoLink: "https://info.uniswap.org/#/lukso/",
    label: "LUKSO",
    nativeCurrency: { name: "LYX", symbol: "LYX", decimals: 18 },
    color: darkTheme.chain_43114,
  },
  [CustomChainId.LUKSO_TESTNET]: {
    networkType: NetworkType.L1,
    docs: "https://docs.lukso.tech/",
    explorer: "https://explorer.execution.testnet.lukso.network",
    infoLink: "https://info.uniswap.org/#/lukso/",
    label: "LUKSO_TESTNET",
    nativeCurrency: { name: "LYXt", symbol: "LYXt", decimals: 18 },
    color: darkTheme.chain_43114,
  },
} as ChainInfoMap;

export function getChainInfo(
  chainId: SupportedL1ChainId,
  featureFlags?: Record<ChainId | SupportedL1ChainId | number, boolean>
): L1ChainInfo;

export function getChainInfo(
  chainId: ChainId | CustomChainId,
  featureFlags?: Record<ChainId | SupportedL1ChainId | number, boolean>
): L1ChainInfo | undefined;

export function getChainInfo(
  chainId: number | undefined,
  featureFlags?: Record<ChainId | SupportedL1ChainId | number, boolean>
): L1ChainInfo | undefined;

/**
 * Overloaded method for returning ChainInfo given a chainID
 * Return type varies depending on input type:
 * number | undefined -> returns chaininfo | undefined
 * ChainId -> returns L1ChainInfo | L2ChainInfo
 * SupportedL1ChainId -> returns L1ChainInfo
 * SupportedL2ChainId -> returns L2ChainInfo
 */
export function getChainInfo(
  chainId: any,
  featureFlags?: Record<ChainId | SupportedL1ChainId | number, boolean>
): any {
  if (featureFlags && chainId in featureFlags) {
    return featureFlags[chainId] ? CHAIN_INFO[chainId] : undefined;
  }
  if (chainId) {
    // TODO review that
    return CHAIN_INFO[42];
  }
  return CHAIN_INFO[42];
}

const MAINNET_INFO = CHAIN_INFO[42];
export function getChainInfoOrDefault(
  chainId: number | undefined,
  featureFlags?: Record<number, boolean>
) {
  return getChainInfo(chainId, featureFlags) ?? MAINNET_INFO;
}
