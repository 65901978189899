import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";

const TokensByTxCountQuery = gql`
  query TokensByTxCount($search: String) {
    tokens(
      first: 25
      orderBy: txCount
      orderDirection: desc
      where: {
        or: [
          { symbol_contains_nocase: $search }
          { name_contains_nocase: $search }
        ]
      }
    ) {
      id
    }
  }
`;

const TokensByTxCountWithoutStringQuery = gql`
  query TokensByTxCountWithoutString {
    tokens(first: 25, orderBy: txCount, orderDirection: desc) {
      id
    }
  }
`;

const client = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions",
  cache: new InMemoryCache(),
});

export function useTokensByTxCountWithString(search: string) {
  let data, loading, error;

  if (search.length === 0) {
    const result = useQuery(TokensByTxCountWithoutStringQuery, {
      fetchPolicy: "no-cache",
    });
    data = result.data;
    loading = result.loading;
    error = result.error;
  } else {
    const result = useQuery(TokensByTxCountQuery, {
      variables: { search },
      fetchPolicy: "no-cache",
    });
    data = result.data;
    loading = result.loading;
    error = result.error;
  }

  return {
    tokenIds: data?.tokens?.map((token: { id: string }) => token.id) || [],
    loading,
    error,
  };
}
