import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { FollowersData } from "./QueryInterfaces";

const FollowersDocument = gql`
  query QueryFollowers($userId: ID!, $skip: Int, $first: Int) {
    user(id: $userId) {
      followers(skip: $skip, first: $first) {
        follower {
          isUniversalProfile
          address
        }
      }
    }
  }
`;

const FolloweesDocument = gql`
  query QueryFollowers($userId: ID!, $skip: Int, $first: Int) {
    user(id: $userId) {
      followings(skip: $skip, first: $first) {
        followee {
          isUniversalProfile
          address
        }
      }
    }
  }
`;

const followsClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/registry",
  cache: new InMemoryCache(),
});

async function fetchFollowers(userId: string) {
  const followers = [];
  let tmpFollowers = [];

  const first = 100;
  let skip = 0;

  let { data, error, loading } = await followsClient.query({
    variables: { userId, skip, first },
    query: FollowersDocument,
    fetchPolicy: "no-cache",
  });

  if (error) {
    throw new Error("Failed to fetch followers");
  }

  tmpFollowers = data;
  if (!tmpFollowers?.user?.followers) {
    return [];
  }

  followers.push(...tmpFollowers.user.followers);

  while (tmpFollowers?.user?.followers?.length === 100) {
    skip = followers.length;
    let result = await followsClient.query({
      variables: { userId, skip, first },
      query: FollowersDocument,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new Error("Failed to fetch followers");
    }

    tmpFollowers = result.data;
    followers.push(...tmpFollowers.user.followers);
  }

  return followers;
}

async function fetchFollowees(userId: string) {
  const followees = [];
  let tmpFollowees = [];

  const first = 100;
  let skip = 0;

  let { data, error, loading } = await followsClient.query({
    variables: { userId, skip, first },
    query: FolloweesDocument,
    fetchPolicy: "no-cache",
  });

  if (error) {
    throw new Error("Failed to fetch followers");
  }

  tmpFollowees = data;

  if (!tmpFollowees?.user?.followings) {
    return [];
  }

  followees.push(...tmpFollowees.user.followings);

  while (tmpFollowees.user.followings.length === 100) {
    skip = followees.length;
    let result = await followsClient.query({
      variables: { userId, skip, first },
      query: FolloweesDocument,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new Error("Failed to fetch followers");
    }

    tmpFollowees = result.data;
    followees.push(...tmpFollowees.user.followings);
  }

  return followees;
}

export async function fetchFollows(_userId: string): Promise<FollowersData> {
  if (!_userId) return { user: { followers: [], followings: [] } };

  const userId = _userId.toLowerCase();
  const followers = await fetchFollowers(userId);
  const followees = await fetchFollowees(userId);

  return {
    user: {
      followers: followers,
      followings: followees,
    },
  };
}
