// Gets all transactions
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { ITransactionResult } from "./QueryInterfaces";

const LatestTransactionsDocument = gql`
  query GetLatestTransactions($timestamp: Int) {
    transactions(where: {timestamp_gt: $timestamp},orderBy: timestamp, orderDirection: desc) {
      burns {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          symbol
          id
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      mints {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      swaps {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          symbol
          id
        }
        timestamp
      }
      timestamp
    }
  }
`;


const latestTransactionsClient = new ApolloClient({
    uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions", // Replace with your desired API URL
    cache: new InMemoryCache(),
});

export interface GetTradesSinceTimestampQueryParams {
  timestamp: number
}

export async function fetchTransactionsSinceTimeStamp(
    queryParams: GetTradesSinceTimestampQueryParams
) : Promise<ITransactionResult[]> {
    const timestamp = queryParams.timestamp;

    const { data, error, loading } = await latestTransactionsClient.query({
        query: LatestTransactionsDocument,
        variables: { timestamp },
        fetchPolicy: 'no-cache',
    })

    if (!error && !loading) {
        return data.transactions;
    }
    return [];
}
