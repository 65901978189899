// Gets all transactions
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { ITransactionResult } from "./QueryInterfaces";
import { TRANSACTIONS_PER_QUERY } from "../utils/Contants";

const FolloweesTransactionsDocument = gql`
  query FolloweesTransactions($followees: [ID], $first: Int, $skip: Int) {
    transactions(
        where: {or: [{burns_: {origin_in: $followees}}, {mints_: {origin_in: $followees}}, {swaps_: {origin_in: $followees}}]}
        orderBy: timestamp,
        orderDirection: desc,
        first: $first,
        skip: $skip) {
      burns {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          symbol
          id
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      mints {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      swaps {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          symbol
          id
        }
        timestamp
      }
      timestamp
    }
  }
`;


const followeesTransactionsClient = new ApolloClient({
    uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions", // Replace with your desired API URL
    cache: new InMemoryCache(),
});

export interface GetFolloweesTradesQueryParams {
  first?: number
  skip?: number
  followees: string[]
}

export async function fetchFolloweesTransactions(queryParams?: GetFolloweesTradesQueryParams) : Promise<ITransactionResult[]> {
    const first = queryParams?.first ?? TRANSACTIONS_PER_QUERY;
    const skip = queryParams?.skip ?? 0;
    const followees = queryParams?.followees;
    
    const { data, error, loading } = await followeesTransactionsClient.query({
        query: FolloweesTransactionsDocument,
        variables: { followees, first, skip },
        fetchPolicy: 'no-cache',
    })

    if (!error && !loading)
        return data.transactions;
    return [];
}