import styled from "styled-components";

import HolidayUniIcon from "./HolidayUniIcon";
import USIcon from "../../assets/images/us_icon_3d.png";

// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string;
  height?: string | number;
  width?: string | number;
  gradientId?: string;
};

export const UniIcon = (props: SVGProps) => (
  <Container>
    <img src={USIcon} alt="uni" {...props} />
  </Container>
);

const Container = styled.div`
  position: relative;
`;
