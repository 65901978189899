import { createMulticall, ListenerOptions } from "@0xinti/redux-multicall";
import { ChainId } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import {
  useInterfaceMulticall,
  useMainnetInterfaceMulticall,
} from "hooks/useContract";
import useBlockNumber, {
  useMainnetBlockNumber,
} from "lib/hooks/useBlockNumber";
import { useMemo } from "react";

const multicall = createMulticall();

export default multicall;

/**
 *
 * @param chainId
 * @returns The approximate whole number of blocks written to the corresponding chainId per Ethereum mainnet epoch.
 */
function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  // TODO(WEB-2437): See if these numbers need to be updated
  switch (chainId) {
    default:
      return 42;
  }
}

export function MulticallUpdater() {
  const { chainId } = useWeb3React();
  const latestBlockNumber = useBlockNumber();
  const latestMainnetBlockNumber = useMainnetBlockNumber();
  const contract = useInterfaceMulticall();
  const mainnetContract = useMainnetInterfaceMulticall();
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId]
  );
  const mainnetListener: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(ChainId.MAINNET),
    }),
    []
  );

  return (
    <>
      <multicall.Updater
        chainId={ChainId.MAINNET}
        latestBlockNumber={latestMainnetBlockNumber}
        contract={mainnetContract}
        listenerOptions={mainnetListener}
      />
      {chainId !== ChainId.MAINNET && (
        <multicall.Updater
          chainId={chainId}
          latestBlockNumber={latestBlockNumber}
          contract={contract}
          listenerOptions={listenerOptions}
        />
      )}
    </>
  );
}
