import { ChainId } from "@uniswap/sdk-core";
import AppRpcProvider from "rpc/AppRpcProvider";
import AppStaticJsonRpcProvider from "rpc/StaticJsonRpcProvider";
import StaticJsonRpcProvider from "rpc/StaticJsonRpcProvider";

import { CustomChainId, SupportedInterfaceChain } from "./chains";
import { RPC_URLS } from "./networks";

const providerFactory = (chainId: SupportedInterfaceChain, i = 0) =>
  new AppStaticJsonRpcProvider(chainId, RPC_URLS[chainId][i]);

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS: {
  [key in SupportedInterfaceChain]: StaticJsonRpcProvider;
} = {
  [ChainId.MAINNET]: new AppRpcProvider(ChainId.MAINNET, [
    providerFactory(ChainId.MAINNET),
    providerFactory(ChainId.MAINNET, 1),
  ]),
  [CustomChainId.LUKSO_MAINNET]: new AppRpcProvider(
    CustomChainId.LUKSO_MAINNET,
    [providerFactory(CustomChainId.LUKSO_MAINNET)]
  ),
  [CustomChainId.LUKSO_TESTNET]: new AppRpcProvider(
    CustomChainId.LUKSO_TESTNET,
    [providerFactory(CustomChainId.LUKSO_TESTNET)]
  ),
};

export const DEPRECATED_RPC_PROVIDERS: {
  [key in SupportedInterfaceChain]: AppStaticJsonRpcProvider;
} = {
  [ChainId.MAINNET]: providerFactory(ChainId.MAINNET),
  [CustomChainId.LUKSO_MAINNET]: providerFactory(CustomChainId.LUKSO_MAINNET),
  [CustomChainId.LUKSO_TESTNET]: providerFactory(CustomChainId.LUKSO_TESTNET),
};
