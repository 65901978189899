// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import {
  InterfaceEventName,
  InterfaceModalName,
} from "@uniswap/analytics-events";
import { ChainId, Currency, CurrencyAmount, Token } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { Trace } from "analytics";
import { useCachedPortfolioBalancesQuery } from "components/PrefetchBalancesWrapper/PrefetchBalancesWrapper";
import { supportedChainIdFromGQLChain } from "graphql/data/util";
import useDebounce from "hooks/useDebounce";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useToggle from "hooks/useToggle";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { getTokenFilter } from "lib/hooks/useTokenList/filtering";
import {
  TokenBalances,
  tokenComparator,
  useSortTokensByQuery,
} from "lib/hooks/useTokenList/sorting";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Text } from "rebass";
import styled, { useTheme } from "styled-components";
import { CloseIcon, ThemedText } from "theme/components";
import { UserAddedToken } from "types/tokens";
import { splitHiddenTokens } from "utils/splitHiddenTokens";

import {
  useDefaultActiveTokens,
  useSearchInactiveTokenLists,
  useToken,
} from "../../hooks/Tokens";
import { isAddress } from "../../utils";
import Column from "../Column";
import Row, { RowBetween } from "../Row";
import CommonBases from "./CommonBases";
import { CurrencyRow, formatAnalyticsEventProperties } from "./CurrencyList";
import CurrencyList from "./CurrencyList";
import { PaddedColumn, SearchInput, Separator } from "./styled";
import { CustomChainId } from "constants/chains";
import {
  NEVER_RELOAD,
  useMultipleContractSingleData,
  useSingleCallResult,
} from "lib/hooks/multicall";
import LSP7Abi from "../../abis/lsp7.json";
import { ethers } from "ethers";
import { useInterfaceMulticall } from "hooks/useContract";
import { MULTICALL_ADDRESSES } from "constants";
import { useTokensByTxCount } from "hooks/useTokensByTxCount";
import { useTokensInfos } from "hooks/useTokenInfos";
import { useTokensByTxCountWithString } from "hooks/useTokensByTxsCount";

const ContentWrapper = styled(Column)`
  background-color: ${({ theme }) => theme.surface1};
  width: 100%;
  overflow: hidden;
  flex: 1 1;
  position: relative;
  border-radius: 20px;
`;

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency, hasWarning?: boolean) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  onlyShowCurrenciesWithBalance?: boolean;
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
  onlyShowCurrenciesWithBalance,
}: CurrencySearchProps) {
  const { chainId, account } = useWeb3React();
  const theme = useTheme();

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false);

  // Refs for fixed size lists
  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);
  const isAddressSearch = isAddress(debouncedQuery);
  const searchToken = useToken(debouncedQuery);

  const defaultTokens = useDefaultActiveTokens(chainId);

  const { data, loading: balancesAreLoading } = useCachedPortfolioBalancesQuery(
    { account }
  );

  const defaultTokensAddresses = useMemo(() => {
    return Object.values(defaultTokens).map((token) => token.address);
  }, [defaultTokens]);

  // Create lsp7 interface contract from LSP7Abi
  const lsp7Interface = new ethers.utils.Interface(LSP7Abi);

  const tokensBalances = useMultipleContractSingleData(
    defaultTokensAddresses,
    lsp7Interface,
    "balanceOf",
    [account],
    NEVER_RELOAD
  );

  const decimals = useMultipleContractSingleData(
    defaultTokensAddresses,
    lsp7Interface,
    "decimals"
  );

  const multicall = useInterfaceMulticall().interface;

  const balance = useMultipleContractSingleData(
    [MULTICALL_ADDRESSES[chainId]],
    multicall,
    "getEthBalance",
    [account]
  );

  // useState to store the initial balances
  const [initialBalances, setInitialBalances] = useState<TokenBalances>({});

  useEffect(() => {
    // This effect runs once upon component mount and whenever `chainId` or `data?.portfolios` changes
    // Check if initialBalances is not set yet and tokensBalances are loaded
    if (
      Object.keys(initialBalances).length === 0 &&
      tokensBalances.every((tb) => tb.loading === false)
    ) {
      let newBalances = tokensBalances.reduce((acc, tokenBalance, index) => {
        const token = defaultTokensAddresses[index];
        const usdValue = 0; // Assuming a way to fetch or calculate USD value

        const decimal = decimals[index]?.result?.[0]
          ? parseInt(decimals[index].result[0])
          : 18;
        const balance = tokenBalance?.result?.[0]
          ? parseFloat(
              ethers.utils.formatUnits(tokenBalance.result[0], decimal)
            )
          : 0;
        acc[token.toLowerCase()] = { usdValue, balance };
        return acc;
      }, {});
      const newBalanceWithEth = {
        ...newBalances,
        ETH: {
          usdValue: 0,
          balance:
            balance.length && balance[0].result?.length
              ? parseFloat(ethers.utils.formatUnits(balance[0].result[0], 18))
              : 0,
        },
      };
      // Set the initialBalances state with the fetched balances
      setInitialBalances(newBalanceWithEth);
    }
  }, [chainId, data?.portfolios, tokensBalances]);

  // Use `initialBalances` for rendering and logic instead of recalculating balances
  let balances = initialBalances;

  const { tokenIds, loading: txCountLoading } =
    useTokensByTxCountWithString(debouncedQuery);

  const { data: tokens, loading: tokenInfosLoading } = useTokensInfos(tokenIds);

  const tokenBalances = useMemo(() => {
    return tokens?.assets?.reduce((balances, token) => {
      const balance =
        token?.holders?.length > 0 ? token.holders[0].balance : "0";
      balances[token.id] = balance;
      return balances;
    }, {} as Record<string, string>);
  }, [tokens]);

  const sortedTokens: Token[] = useMemo(() => {
    const mappedTokens =
      tokens?.assets?.map((token) => {
        return new Token(
          42,
          token.id.toLowerCase(),
          18, // Assuming decimals are 18 for all tokens
          token.lsp4TokenSymbol,
          token.lsp4TokenName
        );
      }) || [];

    if (txCountLoading || tokenInfosLoading) {
      return mappedTokens;
    }

    const adaptedBalances: TokenBalances = {};
    mappedTokens.forEach((token) => {
      const balance = tokenBalances[token.address.toLowerCase()] ?? 0;
      adaptedBalances[token.address.toLowerCase()] = {
        usdValue: 0,
        balance: parseInt(balance),
      };
    });

    const orderedTokens = mappedTokens.sort((a, b) => {
      const indexA = tokenIds.indexOf(a.address.toLowerCase());
      const indexB = tokenIds.indexOf(b.address.toLowerCase());

      if (indexA === -1 && indexB === -1) {
        return tokenComparator(adaptedBalances, a, b);
      }
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }
      return indexA - indexB;
    });

    return orderedTokens;
  }, [tokens, txCountLoading, tokenIds, tokenBalances, tokenInfosLoading]);

  // const filteredSortedTokens = useMemo(() => {
  //   if (onlyShowCurrenciesWithBalance) {
  //     return sortedTokens.filter((token) => {
  //       const balance = tokenBalances[token.address];
  //       return balance && parseFloat(balance) > 0;
  //     });
  //   }
  //   return sortedTokens;
  // }, [sortedTokens, onlyShowCurrenciesWithBalance, tokenBalances]);

  const native = useNativeCurrency(chainId);
  const wrapped = native.wrapped;

  const searchCurrencies: Currency[] = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim();

    const tokens = sortedTokens.filter(
      (t) => !(t.equals(wrapped) || (disableNonToken && t.isNative))
    );
    const shouldShowWrapped =
      !onlyShowCurrenciesWithBalance ||
      (!balancesAreLoading && balances[wrapped.address]?.usdValue > 0);
    const natives = (
      disableNonToken || native.equals(wrapped)
        ? [wrapped]
        : shouldShowWrapped
        ? [native, wrapped]
        : [native]
    ).filter(
      (n) =>
        n.symbol?.toLowerCase()?.indexOf(s) !== -1 ||
        n.name?.toLowerCase()?.indexOf(s) !== -1
    );

    return [...natives, ...tokens];
  }, [
    debouncedQuery,
    onlyShowCurrenciesWithBalance,
    balancesAreLoading,
    balances,
    wrapped,
    disableNonToken,
    native,
  ]);

  const handleCurrencySelect = useCallback(
    (currency: Currency, hasWarning?: boolean) => {
      onCurrencySelect(currency, hasWarning);
      if (!hasWarning) onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === native?.symbol?.toLowerCase()) {
          handleCurrencySelect(native);
        } else if (searchCurrencies.length > 0) {
          if (
            searchCurrencies[0].symbol?.toLowerCase() ===
              debouncedQuery.trim().toLowerCase() ||
            searchCurrencies.length === 1
          ) {
            handleCurrencySelect(searchCurrencies[0]);
          }
        }
      }
    },
    [debouncedQuery, native, searchCurrencies, handleCurrencySelect]
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    !onlyShowCurrenciesWithBalance &&
      (sortedTokens.length === 0 ||
        (debouncedQuery.length > 2 && !isAddressSearch))
      ? debouncedQuery
      : undefined
  );

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true);
    }, 3000);
    return () => clearTimeout(tokenLoaderTimer);
  }, []);

  return (
    <ContentWrapper>
      <Trace
        name={InterfaceEventName.TOKEN_SELECTOR_OPENED}
        modal={InterfaceModalName.TOKEN_SELECTOR}
        shouldLogImpression
      >
        <PaddedColumn gap="16px">
          <RowBetween>
            <Text fontWeight={535} fontSize={16}>
              <Trans>Select a token</Trans>
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <Row>
            <SearchInput
              type="text"
              id="token-search-input"
              data-testid="token-search-input"
              placeholder={t`Search name or paste address`}
              autoComplete="off"
              value={searchQuery}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />
          </Row>
          {showCommonBases && (
            <CommonBases
              chainId={chainId}
              onSelect={handleCurrencySelect}
              selectedCurrency={selectedCurrency}
              searchQuery={searchQuery}
              isAddressSearch={isAddressSearch}
            />
          )}
        </PaddedColumn>
        <Separator />
        {searchToken ? (
          <Column style={{ padding: "20px 0", height: "100%" }}>
            <CurrencyRow
              currency={searchToken}
              isSelected={Boolean(
                searchToken &&
                  selectedCurrency &&
                  selectedCurrency.equals(searchToken)
              )}
              onSelect={(hasWarning: boolean) =>
                searchToken && handleCurrencySelect(searchToken, hasWarning)
              }
              otherSelected={Boolean(
                searchToken &&
                  otherSelectedCurrency &&
                  otherSelectedCurrency.equals(searchToken)
              )}
              showCurrencyAmount={showCurrencyAmount}
              eventProperties={formatAnalyticsEventProperties(
                searchToken,
                0,
                [searchToken],
                searchQuery,
                isAddressSearch
              )}
              balance={
                tryParseCurrencyAmount(
                  String(
                    balances[
                      searchToken.isNative
                        ? "ETH"
                        : searchToken.address?.toLowerCase()
                    ]?.balance ?? 0
                  ),
                  searchToken
                ) ?? CurrencyAmount.fromRawAmount(searchToken, 0)
              }
            />
          </Column>
        ) : sortedTokens?.length > 0 ||
          filteredInactiveTokens?.length > 0 ||
          tokenInfosLoading ? (
          <div style={{ flex: "1" }}>
            <AutoSizer disableWidth>
              {({ height }: { height: number }) => (
                <CurrencyList
                  height={height}
                  currencies={sortedTokens}
                  otherListTokens={filteredInactiveTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  fixedListRef={fixedList}
                  showCurrencyAmount={showCurrencyAmount}
                  isLoading={txCountLoading || tokenInfosLoading}
                  searchQuery={searchQuery}
                  isAddressSearch={isAddressSearch}
                  balances={balances}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <Column style={{ padding: "20px", height: "100%" }}>
            <ThemedText.DeprecatedMain
              color={theme.neutral3}
              textAlign="center"
              mb="20px"
            >
              <Trans>No results found.</Trans>
            </ThemedText.DeprecatedMain>
          </Column>
        )}
      </Trace>
    </ContentWrapper>
  );
}
