// Gets user full profile
// - address (id)
// - name
// - description
// - links
// - profile picture
// - identicon
// - cover picture

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UsersDocument = gql`
  query GetUserProfile($_ids: [String], $skip: Int, $first: Int) {
    profiles(where: { id_in: $_ids }, skip: $skip, first: $first) {
      id
      name
      profileImages {
        url
      }
    }
  }
`;

const userProfileClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile-backup", // Replace with your desired API URL
  cache: new InMemoryCache(),
});

export async function fetchUsers(ids: string[]) {
  const _ids = ids.map((i) => i.toLowerCase());
  const tmpProfiles = [];
  const first = 100;
  for (let i = 0; i < ids.length * 0.01; i++) {
    const skip: number = tmpProfiles.length;
    const { data, error, loading } = await userProfileClient.query({
      variables: { _ids, skip, first },
      query: UsersDocument,
      fetchPolicy: "cache-first",
    });
    tmpProfiles.push(...data.profiles);
  }

  return { profiles: tmpProfiles };
}
