// a list of tokens by chain
import { ChainId, Currency, Token } from "@uniswap/sdk-core";

import {
  DAI,
  nativeOnChain,
  USDC_MAINNET,
  USDT,
  WBTC,
  WLYX_LUKSO_MAINNET,
  WLYX_LUKSO_TESTNET,
  WRAPPED_NATIVE_CURRENCY,
} from "./tokens";
import { CustomChainId } from "./chains";

type ChainTokenList = {
  readonly [chainId: number]: Token[];
};

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[];
};

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
);

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.MAINNET]: [
    nativeOnChain(ChainId.MAINNET),
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
    WRAPPED_NATIVE_CURRENCY[ChainId.MAINNET] as Token,
  ],
  [CustomChainId.LUKSO_MAINNET]: [
    nativeOnChain(CustomChainId.LUKSO_MAINNET),
    WLYX_LUKSO_MAINNET,
  ],
  [CustomChainId.LUKSO_TESTNET]: [
    nativeOnChain(CustomChainId.LUKSO_TESTNET),
    WLYX_LUKSO_TESTNET,
  ],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [ChainId.MAINNET]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.MAINNET],
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
  ],
  [CustomChainId.LUKSO_MAINNET]: [WLYX_LUKSO_MAINNET],
  [CustomChainId.LUKSO_TESTNET]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[CustomChainId.LUKSO_TESTNET],
    WLYX_LUKSO_TESTNET,
  ],
  // TODO: add LUKSO
};
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(
        ChainId.MAINNET,
        "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
        8,
        "cDAI",
        "Compound Dai"
      ),
      new Token(
        ChainId.MAINNET,
        "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
        8,
        "cUSDC",
        "Compound USD Coin"
      ),
    ],
    [USDC_MAINNET, USDT],
    [DAI, USDT],
  ],
};
