import { InterfaceEventName } from "@uniswap/analytics-events";
import { sendAnalyticsEvent } from "analytics";
import clsx from "clsx";
import QueryTokenLogo from "components/Logo/QueryTokenLogo";
import TokenSafetyIcon from "components/TokenSafety/TokenSafetyIcon";
import { checkSearchTokenWarning } from "constants/tokenSafety";
import { useInfoExplorePageEnabled } from "featureFlags/flags/infoExplore";
import {
  Chain,
  TokenStandard,
} from "graphql/data/__generated__/types-and-hooks";
import { SearchToken } from "graphql/data/SearchTokens";
import { getTokenDetailsURL } from "graphql/data/util";
import { Box } from "nft/components/Box";
import { Column, Row } from "nft/components/Flex";
import { VerifiedIcon } from "nft/components/icons";
import { vars } from "nft/css/sprinkles.css";
import { GenieCollection } from "nft/types";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ThemedText } from "theme/components";
import { NumberType, useFormatter } from "utils/formatNumbers";

import { DeltaArrow, DeltaText } from "../Tokens/TokenDetails/Delta";
import { useAddRecentlySearchedAsset } from "./RecentlySearchedAssets";
import * as styles from "./SearchBar.css";
import { IUserResult } from "pages/Social/queries/QueryInterfaces";

const PriceChangeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
  gap: 2px;
`;

interface CollectionRowProps {
  collection: GenieCollection;
  isHovered: boolean;
  setHoveredIndex: (index: number | undefined) => void;
  toggleOpen: () => void;
  index: number;
  eventProperties: Record<string, unknown>;
}

export const CollectionRow = ({
  collection,
  isHovered,
  setHoveredIndex,
  toggleOpen,
  index,
  eventProperties,
}: CollectionRowProps) => {
  const { formatNumberOrString } = useFormatter();

  const [brokenImage, setBrokenImage] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const addRecentlySearchedAsset = useAddRecentlySearchedAsset();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    addRecentlySearchedAsset({
      ...collection,
      isNft: true,
      chain: Chain.Ethereum,
    });
    toggleOpen();
    sendAnalyticsEvent(InterfaceEventName.NAVBAR_RESULT_SELECTED, {
      ...eventProperties,
    });
  }, [addRecentlySearchedAsset, collection, toggleOpen, eventProperties]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isHovered) {
        event.preventDefault();
        navigate(`/nfts/collection/${collection.address}`);
        handleClick();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [toggleOpen, isHovered, collection, navigate, handleClick]);

  return (
    <Link
      to={`/nfts/collection/${collection.address}`}
      onClick={handleClick}
      onMouseEnter={() => !isHovered && setHoveredIndex(index)}
      onMouseLeave={() => isHovered && setHoveredIndex(undefined)}
      className={styles.suggestionRow}
      style={{ background: isHovered ? vars.color.lightGrayOverlay : "none" }}
    >
      <Row style={{ width: "60%" }}>
        {!brokenImage && collection.imageUrl ? (
          <Box
            as="img"
            src={collection.imageUrl}
            alt={collection.name}
            className={clsx(
              loaded ? styles.suggestionImage : styles.imageHolder
            )}
            onError={() => setBrokenImage(true)}
            onLoad={() => setLoaded(true)}
          />
        ) : (
          <Box className={styles.imageHolder} />
        )}
        <Column className={styles.suggestionPrimaryContainer}>
          <Row gap="4" width="full">
            <Box className={styles.primaryText}>{collection.name}</Box>
            {collection.isVerified && (
              <VerifiedIcon className={styles.suggestionIcon} />
            )}
          </Row>
          <Box className={styles.secondaryText}>
            {formatNumberOrString({
              input: collection?.stats?.total_supply,
              type: NumberType.WholeNumber,
            })}{" "}
            items
          </Box>
        </Column>
      </Row>
      {collection.stats?.floor_price ? (
        <Column className={styles.suggestionSecondaryContainer}>
          <Row gap="4">
            <Box className={styles.primaryText}>
              {formatNumberOrString({
                input: collection.stats?.floor_price,
                type: NumberType.NFTToken,
              })}{" "}
              ETH
            </Box>
          </Row>
          <Box className={styles.secondaryText}>Floor</Box>
        </Column>
      ) : null}
    </Link>
  );
};

interface TokenRowProps {
  token: SearchToken;
  isHovered: boolean;
  setHoveredIndex: (index: number | undefined) => void;
  toggleOpen: () => void;
  index: number;
  eventProperties: Record<string, unknown>;
}

export const TokenRow = ({
  token,
  isHovered,
  setHoveredIndex,
  toggleOpen,
  index,
  eventProperties,
}: TokenRowProps) => {
  const addRecentlySearchedAsset = useAddRecentlySearchedAsset();
  const navigate = useNavigate();
  const { formatFiatPrice, formatDelta } = useFormatter();

  const handleClick = useCallback(() => {
    const address =
      !token.address && token.standard === TokenStandard.Native
        ? "NATIVE"
        : token.address;
    address && addRecentlySearchedAsset({ address, chain: token.chain });

    toggleOpen();
    sendAnalyticsEvent(InterfaceEventName.NAVBAR_RESULT_SELECTED, {
      ...eventProperties,
    });
  }, [addRecentlySearchedAsset, token, toggleOpen, eventProperties]);

  const isInfoExplorePageEnabled = useInfoExplorePageEnabled();

  const tokenDetailsPath = getTokenDetailsURL({
    ...token,
    isInfoExplorePageEnabled,
  });
  // Close the modal on escape
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isHovered) {
        event.preventDefault();
        navigate(tokenDetailsPath);
        handleClick();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [toggleOpen, isHovered, token, navigate, handleClick, tokenDetailsPath]);

  return (
    <Link
      data-testid={`searchbar-token-row-${token.chain}-${
        token.address ?? "NATIVE"
      }`}
      to={tokenDetailsPath}
      onClick={handleClick}
      onMouseEnter={() => !isHovered && setHoveredIndex(index)}
      onMouseLeave={() => isHovered && setHoveredIndex(undefined)}
      className={styles.suggestionRow}
      style={{ background: isHovered ? vars.color.lightGrayOverlay : "none" }}
    >
      <Row style={{ width: "65%" }}>
        <QueryTokenLogo
          token={token}
          symbol={token.symbol}
          size="36px"
          backupImg={token.project?.logoUrl}
          style={{ marginRight: "8px" }}
        />
        <Column className={styles.suggestionPrimaryContainer}>
          <Row gap="4" width="full">
            <Box className={styles.primaryText}>{token.name}</Box>
            <TokenSafetyIcon warning={checkSearchTokenWarning(token)} />
          </Row>
          <Box className={styles.secondaryText}>{token.symbol}</Box>
        </Column>
      </Row>

      <Column className={styles.suggestionSecondaryContainer}>
        {!!token.market?.price?.value && (
          <>
            <Row gap="4">
              <Box className={styles.primaryText}>
                {formatFiatPrice({ price: token.market.price.value })}
              </Box>
            </Row>
            <PriceChangeContainer>
              <DeltaArrow delta={token.market?.pricePercentChange?.value} />
              <ThemedText.BodySmall>
                <DeltaText delta={token.market?.pricePercentChange?.value}>
                  {formatDelta(
                    Math.abs(token.market?.pricePercentChange?.value ?? 0)
                  )}
                </DeltaText>
              </ThemedText.BodySmall>
            </PriceChangeContainer>
          </>
        )}
      </Column>
    </Link>
  );
};

interface UserRowProps {
  user: { name: string; id?: string; profilePic: JSX.Element };
  isHovered: boolean;
  setHoveredIndex: (index: number | undefined) => void;
  toggleOpen: () => void;
  index: number;
  eventProperties: Record<string, unknown>;
}

export const UserRow = ({
  user,
  isHovered,
  setHoveredIndex,
  toggleOpen,
  index,
  eventProperties,
}: UserRowProps) => {
  const addRecentlySearchedAsset = useAddRecentlySearchedAsset();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    const address = user.id;

    toggleOpen();
    sendAnalyticsEvent(InterfaceEventName.NAVBAR_RESULT_SELECTED, {
      ...eventProperties,
    });
  }, [addRecentlySearchedAsset, user, toggleOpen, eventProperties]);

  const userDetailsPath = `social/${user.id}`;
  // Close the modal on escape
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isHovered) {
        event.preventDefault();
        navigate(userDetailsPath);
        handleClick();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [toggleOpen, isHovered, user, navigate, handleClick, userDetailsPath]);

  return (
    <Link
      data-testid={`searchbar-user-row-${user.name}-${user.id}`}
      to={userDetailsPath}
      onClick={handleClick}
      onMouseEnter={() => !isHovered && setHoveredIndex(index)}
      onMouseLeave={() => isHovered && setHoveredIndex(undefined)}
      className={styles.suggestionRow}
      style={{ background: isHovered ? vars.color.lightGrayOverlay : "none" }}
    >
      <Row style={{ width: "65%" }}>
        <Column className={styles.suggestionPrimaryContainer}>
          <Row gap="4" width="full">
            {user.profilePic}
            <Row alignItems={"baseline"}>
              <Box className={styles.primaryText}>{user.name}</Box>
              <Box className={styles.secondaryText}>
                {"#" + user.id?.slice(2, 6)}
              </Box>
            </Row>
          </Row>
        </Column>
      </Row>
    </Link>
  );
};

export const SkeletonRow = () => {
  return (
    <Row className={styles.suggestionRow}>
      <Row width="full">
        <Box className={styles.imageHolder} />
        <Column gap="4" width="full">
          <Row justifyContent="space-between">
            <Box
              borderRadius="round"
              height="20"
              background="surface2"
              style={{ width: "180px" }}
            />
            <Box
              borderRadius="round"
              height="20"
              width="48"
              background="surface2"
            />
          </Row>

          <Row justifyContent="space-between">
            <Box
              borderRadius="round"
              height="16"
              width="120"
              background="surface2"
            />
            <Box
              borderRadius="round"
              height="16"
              width="48"
              background="surface2"
            />
          </Row>
        </Column>
      </Row>
    </Row>
  );
};
