import tokenLogoLookup from "constants/tokenLogoLookup";
import { isLukso, isLuksoTestnet, nativeOnChain } from "constants/tokens";
import { checkWarning, WARNING_LEVEL } from "constants/tokenSafety";
import {
  chainIdToNetworkName,
  getNativeLogoURI,
} from "lib/hooks/useCurrencyLogoURIs";
import uriToHttp from "lib/utils/uriToHttp";
import { useCallback, useEffect, useState } from "react";
import { isAddress } from "utils";

import luksoLogo from "../assets/svg/lukso.svg";
import { ERC725YDataKeys } from "@lukso/lsp-smart-contracts";
import { ERC725 } from "@erc725/erc725.js";
import LSP4DigitalAssetSchemas from "@erc725/erc725.js/schemas/LSP4DigitalAsset.json";
import { ipfsUrlToGatewayUrl } from "constants/luksoUtils";
import { RPC_URLS } from "constants/networks";

const BAD_SRCS: { [tokenAddress: string]: true } = {};

// Converts uri's into fetchable urls
function parseLogoSources(uris: string[]) {
  const urls: string[] = [];
  uris.forEach((uri) => urls.push(...uriToHttp(uri)));
  return urls;
}

// Parses uri's, favors non-coingecko images, and improves coingecko logo quality
function prioritizeLogoSources(uris: string[]) {
  const parsedUris = uris.map((uri) => uriToHttp(uri)).flat(1);
  const preferredUris: string[] = [];

  // Consolidate duplicate coingecko urls into one fallback source
  let coingeckoUrl: string | undefined = undefined;

  parsedUris.forEach((uri) => {
    if (uri.startsWith("https://assets.coingecko")) {
      if (!coingeckoUrl) {
        coingeckoUrl = uri.replace(/small|thumb/g, "large");
      }
    } else {
      preferredUris.push(uri);
    }
  });
  // Places coingecko urls in the back of the source array
  return coingeckoUrl ? [...preferredUris, coingeckoUrl] : preferredUris;
}

function getInitialUrl(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  backupImg?: string | null
) {
  if (chainId && isNative) return getNativeLogoURI(chainId);

  const networkName = chainId ? chainIdToNetworkName(chainId) : "ethereum";
  const checksummedAddress = isAddress(address);

  if (
    chainId &&
    isLukso(chainId) &&
    address === nativeOnChain(chainId).wrapped.address
  ) {
    return luksoLogo;
  }

  if (
    chainId &&
    isLuksoTestnet(chainId) &&
    address === nativeOnChain(chainId).wrapped.address
  ) {
    return luksoLogo;
  }

  if (checksummedAddress) {
    return;
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${checksummedAddress}/logo.png`;
  } else {
    return backupImg ?? undefined;
  }
}

interface Verification {
  method: "keccak256(bytes)" | "ecdsa";
  data: string;
  source?: string;
}

interface Image {
  width: number;
  height: number;
  url: string;
  verification?: Verification;
}

interface Asset {
  url: string;
  fileType?: string;
  verification?: Verification;
  address?: string;
  tokenId?: string; // Assuming "32bytes" is a placeholder for a string type indicating the length of the token ID
}

interface Attribute {
  key: string;
  value: string;
  type: "string" | "number" | "boolean";
}

interface LSP4Metadata {
  name: string;
  description: string;
  links: Array<{
    title: string;
    url: string;
  }>;
  icon: Image[];
  images: Image[][];
  assets: Asset[];
  attributes: Attribute[];
}

// Example of a root object that includes LSP4Metadata
interface ERC725YMetadata {
  value: {
    LSP4Metadata: LSP4Metadata;
  };
}

export default function useAssetLogoSource(
  address?: string | null,
  chainId?: number | null,
  isNative?: boolean,
  backupImg?: string | null
): [string | undefined, () => void] {
  const hideLogo = Boolean(
    address && checkWarning(address, chainId)?.level === WARNING_LEVEL.BLOCKED
  );
  const [current, setCurrent] = useState<string | undefined>(
    hideLogo ? undefined : getInitialUrl(address, chainId, isNative, backupImg)
  );
  const [fallbackSrcs, setFallbackSrcs] = useState<string[] | undefined>(
    undefined
  );

  const fetchLSP7Icon = async (address: string) => {
    const ipfsConfig = {
      ipfsGateway: "https://api.universalprofile.cloud/ipfs",
    };

    const erc725js = new ERC725(
      LSP4DigitalAssetSchemas,
      address,
      RPC_URLS[chainId as 42 | 4201][0],
      ipfsConfig
    );
    let metadata: ERC725YMetadata = {
      value: {
        LSP4Metadata: {
          name: "",
          description: "",
          links: [],
          icon: [],
          images: [],
          assets: [],
          attributes: [],
        },
      },
    };

    try {
      metadata = await erc725js.fetchData(ERC725YDataKeys.LSP4.LSP4Metadata);
    } catch (error) {
      if (!error.message.includes("Value of key")) {
        console.error("Error fetching metadata and creators", error);
      }
    }

    if (metadata.value?.LSP4Metadata?.icon.length > 0) {
      if (metadata.value.LSP4Metadata.icon[0].url.startsWith("ipfs")) {
        const url = ipfsUrlToGatewayUrl(
          metadata.value.LSP4Metadata.icon[0].url
        );
        setCurrent(ipfsUrlToGatewayUrl(url));
      } else {
        setCurrent(metadata.value.LSP4Metadata.icon[0].url);
      }
    } else if (metadata.value?.LSP4Metadata?.images.length > 0) {
      if (metadata.value?.LSP4Metadata?.images[0][0].url.startsWith("ipfs")) {
        const url = ipfsUrlToGatewayUrl(
          metadata.value?.LSP4Metadata?.images[0][0].url
        );
        setCurrent(ipfsUrlToGatewayUrl(url));
      } else {
        setCurrent(metadata.value?.LSP4Metadata?.images[0][0].url);
      }
    } else if (metadata.value?.url) {
      if (metadata.value?.url.startsWith("data:application/json;base64")) {
        const base64 = metadata.value?.url.split(",")[1];
        const decoded = atob(base64);
        const parsed = JSON.parse(decoded);
        let url =
          parsed.LSP4Metadata?.icon.length > 0 &&
          (parsed.LSP4Metadata?.icon[0].url as string);

        if (url && url.startsWith("ipfs")) {
          url = ipfsUrlToGatewayUrl(url);
        }
        if (url) {
          setCurrent(url);
        }
      }
    }
  };

  useEffect(() => {
    if (hideLogo) return;
    setCurrent(getInitialUrl(address, chainId, isNative));
    setFallbackSrcs(undefined);
    address && fetchLSP7Icon(address);
  }, [address, chainId, hideLogo, isNative]);

  const nextSrc = useCallback(() => {
    if (current) {
      BAD_SRCS[current] = true;
    }
    // Parses and stores logo sources from tokenlists if assets repo url fails
    if (!fallbackSrcs) {
      const uris = tokenLogoLookup.getIcons(address, chainId) ?? [];
      if (backupImg) uris.push(backupImg);
      const tokenListIcons = prioritizeLogoSources(parseLogoSources(uris));

      setCurrent(tokenListIcons.find((src) => !BAD_SRCS[src]));
      setFallbackSrcs(tokenListIcons);
    } else {
      setCurrent(fallbackSrcs.find((src) => !BAD_SRCS[src]));
    }
  }, [current, fallbackSrcs, address, chainId, backupImg]);

  return [current, nextSrc];
}
