import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { ipfsUrlToGatewayUrl } from "constants/luksoUtils";
import { useLYXPriceRatio } from "hooks/useLYXPriceRatio";
import { useMemo, useState, useEffect } from "react";
import tokenDefault from "assets/svg/token-default.svg";

const searchTokenClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile-backup",
  cache: new InMemoryCache(),
});

const searchTokenDayDataClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions",
  cache: new InMemoryCache(),
});

const SEARCH_TOKENS_QUERY = gql`
  query SearchTokensAndAssets($searchQuery: String!) {
    assets(
      where: {
        or: [
          { lsp4TokenSymbol_contains_nocase: $searchQuery }
          { lsp4TokenName_contains_nocase: $searchQuery }
        ]
        # isLSP7: true
      }
    ) {
      lsp4TokenName
      decimals
      lsp4TokenSymbol
      id
      icons(first: 1) {
        url
      }
      tokens {
        baseAsset {
          id
          lsp4TokenName
          lsp4TokenSymbol
        }
        lsp8TokenIdFormat
        lsp4TokenName
        lsp4TokenSymbol
        tokenId
        icons(first: 1) {
          url
        }
      }
    }
  }
`;

const TOKEN_DAY_DATA_QUERY = gql`
  query TokenDayData($id: ID) {
    tokenDayDatas(
      where: { token_: { id: $id } }
      orderBy: date
      orderDirection: desc
      first: 2
    ) {
      high
      close
      open
      volumeUSD
      low
    }
  }
`;

export type SearchToken = {
  id: string;
  decimals: number;
  name: string;
  chain: string;
  standard: string;
  address: string;
  symbol: string;
  market: {
    id: string;
    price: {
      id: string;
      value: number;
      currency: string;
    };
    pricePercentChange: {
      id: string;
      value: number;
    };
    volume24H: {
      id: string;
      value: number;
      currency: string;
    };
  };
  project: {
    id: string;
    logoUrl: string;
    safetyLevel: string;
  };
};

export async function fetchSearchTokens(
  searchQuery: string,
  ratioLYXPrice: number | undefined
): Promise<SearchToken[]> {
  const { data } = await searchTokenClient.query({
    query: SEARCH_TOKENS_QUERY,
    variables: { searchQuery },
  });

  const tokensWithData = await Promise.all(
    data.assets.map(async (asset: any) => {
      const { data: tokenDayData } = await searchTokenDayDataClient.query({
        query: TOKEN_DAY_DATA_QUERY,
        variables: { id: asset.id },
      });

      let latestTokenDayData = {
        close: "0",
        volumeUSD: "0",
      };

      let secondToLastTokenDayData = {
        close: "0",
      };

      if (tokenDayData.tokenDayDatas.length > 0) {
        latestTokenDayData = tokenDayData.tokenDayDatas[0];

        if (tokenDayData.tokenDayDatas.length > 1) {
          secondToLastTokenDayData = tokenDayData.tokenDayDatas[1];
        }
      }

      const ratio = ratioLYXPrice ?? 1;

      return {
        id: asset.id,
        decimals: asset.decimals,
        name: asset.lsp4TokenName,
        chain: "Lukso",
        standard: "LSP7",
        address: asset.id,
        symbol: asset.lsp4TokenSymbol,
        market: {
          id: asset.id,
          price: {
            id: asset.id,
            value: ratio * parseFloat(latestTokenDayData.close),
            currency: "USD",
          },
          pricePercentChange: {
            id: asset.id,
            value:
              secondToLastTokenDayData.close !== "0"
                ? (latestTokenDayData.close /
                    parseFloat(secondToLastTokenDayData.close) -
                    1) *
                  100
                : 0,
          },
          volume24H: {
            id: asset.id,
            value: parseFloat(latestTokenDayData.volumeUSD),
            currency: "USD",
          },
        },

        project: {
          id: asset.id,
          logoUrl: asset.icons[0]?.url.startsWith("ipfs://")
            ? ipfsUrlToGatewayUrl(asset.icons[0]?.url)
            : asset.icons[0]?.url || tokenDefault,
          safetyLevel: "high",
        },
      };
    })
  );

  return tokensWithData as SearchToken[];
}

export function useSearchTokens(
  searchQuery: string,
  ratioLYXPrice: number | undefined
) {
  const [data, setData] = useState<SearchToken[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const memoizedSearchQuery = useMemo(() => searchQuery, [searchQuery]);

  useEffect(() => {
    const fetchData = async () => {
      if (!memoizedSearchQuery) {
        setData([]);
        setLoading(false);
        setError(null);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const tokens = await fetchSearchTokens(
          memoizedSearchQuery,
          ratioLYXPrice
        );

        setData(tokens);
        setLoading(false);
      } catch (error) {
        setData([]);
        setLoading(false);
        setError(error);
      }
    };

    fetchData();
  }, [memoizedSearchQuery]);

  return {
    data,
    loading,
    error,
  };
}
