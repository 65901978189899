import { AddressMap } from "@uniswap/smart-order-router";
import { ethers } from "ethers";

export const MULTICALL_ADDRESSES: AddressMap = {
  [42]: "0x8aeBEc63A0D2a05ECAdAbFa7bb773443638A45e6",
  [4201]: "0x27C0A37Eb3d8fae10e294a4FaAa985570C28fFF1",
};

export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [42]: "0x8130c332dddf8964b08eab86aad3999017436a6e",
  [1]: "0xa06B05C75f6Eda79a9e199F43d3d37384c976ddD",
  [4201]: "0x1a1FCAc140A8f885A33e2F5233e728E0ca84Bc5B",
};

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [1]: "0xA13e22546e24a9E804e8f1126aF59233507A1992",
  [42]: "0x855BB3e40261A73Dd4fc691fc024cc7d60794D00",
  [4201]: "0x57C3FfdC9e9A8DB8b7409418E90Baa8aC650a0A3",
};
