import {
  ChainId,
  SUPPORTED_CHAINS,
  SupportedChainsType,
} from "@uniswap/sdk-core";

export declare type ExtendedChainId = ChainId | CustomChainId;

export enum CustomChainId {
  LUKSO_MAINNET = 42,
  LUKSO_TESTNET = 4201,
  // Add other custom chain IDs here
}

// extend SUPPORTED_CHAINS with LUKSO_MAINNET and LUKSO_TESTNET
export const SUPPORTED_CHAINS_AND_LUKSO = [
  42, // LUKSO_MAINNET
  // 4201, // LUKSO_TESTNET
  ...SUPPORTED_CHAINS,
] as const;

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.MAINNET]: "mainnet",
  [CustomChainId.LUKSO_MAINNET]: "LUKSO",
  [CustomChainId.LUKSO_TESTNET]: "lukso-testnet",
} as const;

// Include ChainIds in this array if they are not supported by the UX yet, but are already in the SDK.
const NOT_YET_UX_SUPPORTED_CHAIN_IDS: number[] = [];

// TODO: include BASE_GOERLI when routing is implemented
export type SupportedInterfaceChain =
  | ChainId.MAINNET
  | CustomChainId.LUKSO_MAINNET
  | CustomChainId.LUKSO_TESTNET;
export function isSupportedChain(
  chainId: number | null | undefined | ChainId,
  featureFlags?: Record<number, boolean>
): chainId is SupportedInterfaceChain {
  if (featureFlags && chainId && chainId in featureFlags) {
    return featureFlags[chainId];
  }
  return (
    !!chainId &&
    SUPPORTED_CHAINS_AND_LUKSO.indexOf(chainId) !== -1 &&
    NOT_YET_UX_SUPPORTED_CHAIN_IDS.indexOf(chainId) === -1
  );
}

export function asSupportedChain(
  chainId: number | null | undefined | ChainId,
  featureFlags?: Record<number, boolean>
): SupportedInterfaceChain | undefined {
  if (!chainId) return undefined;
  if (featureFlags && chainId in featureFlags && !featureFlags[chainId]) {
    return undefined;
  }
  return isSupportedChain(chainId) ? chainId : undefined;
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  ChainId.MAINNET,
  42, // LUKSO_MAINNET
  4201, // LUKSO_TESTNET
] as const;

/**
 * Supported networks for V2 pool behavior.
 */
export const SUPPORTED_V2POOL_CHAIN_IDS = [ChainId.MAINNET] as const;

export const TESTNET_CHAIN_IDS = [
  // CustomChainId.LUKSO_TESTNET, // LUKSO_TESTNET
] as const;

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  CustomChainId.LUKSO_MAINNET, // LUKSO_MAINNET
  // CustomChainId.LUKSO_TESTNET,
];

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number];

/**
 * Get the priority of a chainId based on its relevance to the user.
 * @param {ChainId} chainId - The chainId to determine the priority for.
 * @returns {number} The priority of the chainId, the lower the priority, the earlier it should be displayed, with base of MAINNET=0.
 */
export function getChainPriority(chainId: ChainId | CustomChainId): number {
  switch (chainId) {
    case CustomChainId.LUKSO_MAINNET:
      return 0;
    case CustomChainId.LUKSO_TESTNET:
      return 1;
    case ChainId.MAINNET:
      return 2;
    default:
      return 3;
  }
}

// TODO: To be fefined
export function isUniswapXSupportedChain(chainId: number) {
  return chainId === ChainId.MAINNET;
}
