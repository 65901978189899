import { ApolloClient, InMemoryCache, from, gql, useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";
import { useMemo } from 'react';
import { tr } from "make-plural";

const client = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions",
  cache: new InMemoryCache(),
});

const QUERY_USER_SWAPS = gql`
  query GetUserSwaps($user: Bytes!, $tokenOut: String!) {
    swaps(where: { origin: $user, token0: $tokenOut }) {
      id
      amount0
    }
  }
`;

export const SPECIFIC_TOKEN_ADDRESS = "0x16f371bc554c38e4bb8f5e6d2617c9ae3dd5dcbf";

export function useHasPrevBoughtToken(tokenAddress: string, currentSwapAmount?: BigNumber) {
  const { account } = useWeb3React();


  const { data, loading, error } = useQuery(QUERY_USER_SWAPS, {
    variables: { user: account?.toLowerCase(), tokenOut: SPECIFIC_TOKEN_ADDRESS.toLowerCase() },
    client
  });

  const hasBoughtToken = useMemo(() => {


    if (tokenAddress?.toLowerCase() !== SPECIFIC_TOKEN_ADDRESS.toLowerCase()) {
      return true; // Always return true for other tokens
    }

    if(!currentSwapAmount) {
      return false;
    }


    // if ((!data || !data.swaps || !currentSwapAmount) ) return false;


    let totalPreviousSwaps = !data?.swaps ? BigNumber.from(0) : data.swaps.reduce((sum, swap: {
      amount0: string;
    }) => {

      return sum.add(-parseInt(swap.amount0));
    }


    , BigNumber.from(0));
    totalPreviousSwaps = totalPreviousSwaps.mul(BigNumber.from(10).pow(18));
    const threshold = BigNumber.from("2500000000000000000000").add(totalPreviousSwaps) // 2500 tokens

    if (currentSwapAmount.lte(threshold)) {
      return true; // Return true if current amount is less than or equal to 10,000
    }

    // Return false if the current swap amount is greater than the sum of previous swaps plus 10,000 tokens
    return !currentSwapAmount.gt(totalPreviousSwaps.add(threshold));

  }, [data, tokenAddress, currentSwapAmount]);

  return { hasBoughtToken, loading, error };
}
