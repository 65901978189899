import { generateBlockie } from "./generateUniconV2";
import { isValidEthAddress } from "./utils";

export interface UniconV2Props {
  address: string; // renamed to 'address'
  size?: number; // Optional size prop
}

export const UniconV2: React.FC<UniconV2Props> = ({ address, size = 32 }) => {
  if (!address || !isValidEthAddress(address)) {
    return null;
  }

  const blockieUrl = generateBlockie(address, size);

  return <img src={blockieUrl} alt="Blockie" width={size} height={size} />;
};
