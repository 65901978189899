import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";

export interface LyxPriceApi {
  current: number;
}

export const LYX_PRICE = gql`
  query LyxPrice {
    token(id: "0x2db41674f2b882889e5e1bd09a3f3613952bc472") {
      name
      symbol
      totalSupply
      decimals
      derivedETH
      tokenDayData(first: 1, orderBy: date, orderDirection: desc) {
        priceUSD
      }
    }
  }
`;

export const luksoClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions",
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
      Pool: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

async function fetchLYXPrice(): Promise<{
  data: LyxPriceApi | undefined;
  error: boolean;
}> {
  try {
    const response = await fetch(
      "https://api.mobula.io/api/1/market/data?asset=lukso",
      // no cors
      {
        method: "GET",
        headers: {
          Authorization: "5a7858e5-b1c5-4904-b5bf-8619a9b91691",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

    const price = data?.data.price;

    const currentPrice = data?.data.price;
    const priceChange24h = data?.data.price_change_24h;
    const priceChange7d = data?.data.price_change_7d;

    // Calculate the price 24 hours ago
    const changeDecimal24hAgo = priceChange24h / 100;
    const price24hAgo = currentPrice / (1 + changeDecimal24hAgo);

    // Calculate the price 7 days ago
    const changeDecimal7dAgo = priceChange7d / 100;
    const price7dAgo = currentPrice / (1 + changeDecimal7dAgo);

    if (price) {
      return {
        data: {
          current: parseFloat(price ?? 0),
        },
        error: false,
      };
    } else {
      return {
        data: undefined,
        error: true,
      };
    }
  } catch (e) {
    console.log("error fetching price");
    console.log(e);
    return {
      data: undefined,
      error: true,
    };
  }
}

export function useLYXPriceRatio(): number | undefined {
  const [ratio, setRatio] = useState<number>();
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        const { data: apiData, error: apiError } = await fetchLYXPrice();
        const { data: gqlData, error: gqlError } = await luksoClient.query({
          query: LYX_PRICE,
        });

        if (apiError || gqlError) {
          setError(true);
        } else if (apiData && gqlData) {
          const apiPrice = apiData.current;
          const gqlPrice = parseFloat(
            gqlData.token.tokenDayData[0].priceUSD ?? 0
          );

          if (apiPrice && gqlPrice) {
            setRatio(apiPrice / gqlPrice);
          } else {
            setError(true);
          }
        }
      } catch (e) {
        console.log("error fetching price");
        console.log(e);
        setError(true);
      }
    }

    if (!ratio && !error) {
      fetch();
    }
  }, [ratio, error, luksoClient]);

  return ratio;
}
