import { ChainId } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { useIsNftPage } from "hooks/useIsNftPage";
import { useEffect } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";

import { darkTheme, lightTheme } from "../colors";

const initialStyles = {
  width: "200vw",
  height: "200vh",
  transform: "translate(-50vw, -100vh)",
};
const backgroundResetStyles = {
  width: "100vw",
  height: "100vh",
  transform: "unset",
};

type TargetBackgroundStyles =
  | typeof initialStyles
  | typeof backgroundResetStyles;

const backgroundRadialGradientElement = document.getElementById(
  "background-radial-gradient"
);
const setBackground = (newValues: TargetBackgroundStyles) =>
  Object.entries(newValues).forEach(([key, value]) => {
    if (backgroundRadialGradientElement) {
      backgroundRadialGradientElement.style[
        key as keyof typeof backgroundResetStyles
      ] = value;
    }
  });

function setDefaultBackground(
  backgroundRadialGradientElement: HTMLElement,
  darkMode?: boolean
) {
  setBackground(initialStyles);
  const defaultLightGradient =
    "radial-gradient(100% 100% at 50% 0%, rgba(255, 184, 226, 0) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF";
  const defaultDarkGradient =
    "linear-gradient(180deg, #131313 0%, #131313 100%)";
  backgroundRadialGradientElement.style.background = darkMode
    ? defaultDarkGradient
    : defaultLightGradient;
}

export default function RadialGradientByChainUpdater(): null {
  const { chainId } = useWeb3React();
  const [darkMode] = useDarkModeManager();
  const isNftPage = useIsNftPage();

  // manage background color
  useEffect(() => {
    if (!backgroundRadialGradientElement) {
      return;
    }

    if (isNftPage) {
      setBackground(initialStyles);
      backgroundRadialGradientElement.style.background = darkMode
        ? darkTheme.surface1
        : lightTheme.surface1;
      return;
    }

    switch (chainId) {
      default: {
        setDefaultBackground(backgroundRadialGradientElement, darkMode);
      }
    }
  }, [darkMode, chainId, isNftPage]);
  return null;
}
