import { ChainId } from "@uniswap/sdk-core";
import { getChainInfo } from "constants/chainInfo";
import {
  CustomChainId,
  isSupportedChain,
  SupportedInterfaceChain,
} from "constants/chains";
import { CSSProperties, FunctionComponent } from "react";
import { useTheme } from "styled-components";
import { useIsDarkMode } from "theme/components/ThemeToggle";

import { ReactComponent as ethereum } from "./ChainSymbols/ethereum.svg";
import { ReactComponent as lukso } from "./ChainSymbols/lukso.svg";

type SVG = FunctionComponent<React.SVGProps<SVGSVGElement>>;
type ChainUI = { Symbol: SVG; bgColor: string; textColor: string };

export function getChainUI(
  chainId: SupportedInterfaceChain,
  darkMode: boolean
): ChainUI;
export function getChainUI(
  chainId: ChainId | CustomChainId,
  darkMode: boolean
): ChainUI | undefined {
  switch (chainId) {
    case ChainId.MAINNET:
      return {
        Symbol: lukso,
        bgColor: "#FF042033",
        textColor: "#FF0420",
      };
    case CustomChainId.LUKSO_MAINNET:
    case CustomChainId.LUKSO_TESTNET:
      return {
        // TODO add lukso
        Symbol: lukso,
        bgColor: "#FF042033",
        textColor: "#FF0420",
      };

    default:
      return {
        // TODO add lukso
        Symbol: lukso,
        bgColor: "#FF042033",
        textColor: "#FF0420",
      };
  }
}

export const getDefaultBorderRadius = (size: number) => size / 2 - 4;

type ChainLogoProps = {
  chainId: ChainId | CustomChainId;
  className?: string;
  size?: number;
  borderRadius?: number;
  style?: CSSProperties;
  testId?: string;
};
export function ChainLogo({
  chainId,
  className,
  style,
  size = 16,
  borderRadius = getDefaultBorderRadius(size),
  testId,
}: ChainLogoProps) {
  const darkMode = useIsDarkMode();
  const { surface2 } = useTheme();

  if (!isSupportedChain(chainId)) return null;
  const { label } = getChainInfo(chainId);

  const { Symbol, bgColor } = getChainUI(chainId, darkMode);
  return (
    <svg
      width={size}
      height={size}
      className={className}
      style={style}
      aria-labelledby="titleID"
      data-testid={testId}
    >
      <title id="titleID">{`${label} logo`}</title>
      <rect rx={borderRadius} fill={surface2} width={size} height={size} />
      <rect rx={borderRadius} fill={bgColor} width={size} height={size} />
      <Symbol width={size} height={size} />
    </svg>
  );
}
