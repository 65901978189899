import { Trans } from "@lingui/macro";
import {
  ChainId,
  Currency,
  CurrencyAmount,
  Percent,
  TradeType,
} from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { Field } from "components/swap/constants";
import { useConnectionReady } from "connection/eagerlyConnect";
import useAutoSlippageTolerance from "hooks/useAutoSlippageTolerance";
import { useDebouncedTrade } from "hooks/useDebouncedTrade";
import { useSwapTaxes } from "hooks/useSwapTaxes";
import { useUSDPrice } from "hooks/useUSDPrice";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ParsedQs } from "qs";
import { ReactNode, useCallback, useMemo } from "react";
import { InterfaceTrade, TradeState } from "state/routing/types";
import {
  isClassicTrade,
  isSubmittableTrade,
  isUniswapXTrade,
} from "state/routing/utils";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";

import { TOKEN_SHORTHANDS } from "../../constants/tokens";
import { useCurrency } from "../../hooks/Tokens";
import useENS from "../../hooks/useENS";
import { isAddress } from "../../utils";
import { useCurrencyBalances } from "../connection/hooks";
import { SwapState, useSwapContext } from "./SwapContext";

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: (
    newOutputHasTax: boolean,
    previouslyEstimatedOutput: string
  ) => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const { setSwapState } = useSwapContext();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      const currencyId = currency.isToken
        ? currency.address
        : currency.isNative
        ? "ETH"
        : "";
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
      setSwapState((state) => {
        if (currencyId === state[otherField].currencyId) {
          // the case where we have to swap the order
          return {
            ...state,
            independentField:
              state.independentField === Field.INPUT
                ? Field.OUTPUT
                : Field.INPUT,
            [field]: { currencyId },
            [otherField]: { currencyId: state[field].currencyId },
          };
        } else {
          // the normal case
          return {
            ...state,
            [field]: { currencyId },
          };
        }
      });
    },
    [setSwapState]
  );

  const onSwitchTokens = useCallback(
    (newOutputHasTax: boolean, previouslyEstimatedOutput: string) => {
      setSwapState((state) => {
        if (newOutputHasTax && state.independentField === Field.INPUT) {
          // To prevent swaps with FOT tokens as exact-outputs, we leave it as an exact-in swap and use the previously estimated output amount as the new exact-in amount.
          return {
            ...state,
            [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
            [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
            typedValue: previouslyEstimatedOutput,
          };
        }

        return {
          ...state,
          independentField:
            state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
          [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
        };
      });
    },
    [setSwapState]
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSwapState((state) => {
        return {
          ...state,
          independentField: field,
          typedValue,
        };
      });
    },
    [setSwapState]
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      setSwapState((state) => ({ ...state, recipient }));
    },
    [setSwapState]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f": true, // v2 factory
  "0xf164fC0Ec4E93095b804a4795bBe1e041497b92a": true, // v2 router 01
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D": true, // v2 router 02
};

export type SwapInfo = {
  currencies: { [field in Field]?: Currency };
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  inputTax: Percent;
  outputTax: Percent;
  outputFeeFiatValue?: number;
  parsedAmount?: CurrencyAmount<Currency>;
  inputError?: ReactNode;
  trade: {
    trade?: InterfaceTrade;
    state: TradeState;
    uniswapXGasUseEstimateUSD?: number;
    error?: any;
    swapQuoteLatency?: number;
  };
  allowedSlippage: Percent;
  autoSlippage: Percent;
};

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(
  state: SwapState,
  chainId: ChainId | undefined
): SwapInfo {
  const { account } = useWeb3React();

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = state;

  const inputCurrency = useCurrency(inputCurrencyId, chainId);
  const outputCurrency = useCurrency(outputCurrencyId, chainId);

  const recipientLookup = useENS(recipient ?? undefined);
  const to: string | null =
    (recipient === null ? account : recipientLookup.address) ?? null;

  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken ? inputCurrency.address : undefined,
    outputCurrency?.isToken ? outputCurrency.address : undefined
  );

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency]
    )
  );

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = useMemo(
    () =>
      tryParseCurrencyAmount(
        typedValue,
        (isExactIn ? inputCurrency : outputCurrency) ?? undefined
      ),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  );

  const trade = useDebouncedTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined,
    undefined,
    account
  );

  const { data: outputFeeFiatValue } = useUSDPrice(
    isSubmittableTrade(trade.trade) && trade.trade.swapFee
      ? CurrencyAmount.fromRawAmount(
          trade.trade.outputAmount.currency,
          trade.trade.swapFee.amount
        )
      : undefined,
    trade.trade?.outputAmount.currency
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  );

  // allowed slippage for classic trades is either auto slippage, or custom user defined slippage if auto slippage disabled
  const classicAutoSlippage = useAutoSlippageTolerance(
    isClassicTrade(trade.trade) ? trade.trade : undefined
  );

  // slippage for uniswapx trades is defined by the quote response
  const uniswapXAutoSlippage = isUniswapXTrade(trade.trade)
    ? trade.trade.slippageTolerance
    : undefined;

  // Uniswap interface recommended slippage amount
  const autoSlippage = uniswapXAutoSlippage ?? classicAutoSlippage;
  const classicAllowedSlippage =
    useUserSlippageToleranceWithDefault(autoSlippage);

  // slippage amount used to submit the trade
  const allowedSlippage = uniswapXAutoSlippage ?? classicAllowedSlippage;

  const connectionReady = useConnectionReady();
  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined;

    if (!account) {
      inputError = connectionReady ? (
        <Trans>Connect wallet</Trans>
      ) : (
        <Trans>Connecting wallet...</Trans>
      );
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>;
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>;
    }

    const formattedTo = isAddress(to);
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>;
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <Trans>Invalid recipient</Trans>;
      }
    }

    // compare input balance to max input based on version
    const [balanceIn, maxAmountIn] = [
      currencyBalances[Field.INPUT],
      trade?.trade?.maximumAmountIn(allowedSlippage),
    ];

    if (balanceIn && maxAmountIn && balanceIn.lessThan(maxAmountIn)) {
      inputError = (
        <Trans>Insufficient {balanceIn.currency.symbol} balance</Trans>
      );
    }

    return inputError;
  }, [
    account,
    currencies,
    parsedAmount,
    to,
    currencyBalances,
    trade?.trade,
    allowedSlippage,
    connectionReady,
  ]);

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      trade,
      autoSlippage,
      allowedSlippage,
      outputFeeFiatValue,
      inputTax,
      outputTax,
    }),
    [
      allowedSlippage,
      autoSlippage,
      currencies,
      currencyBalances,
      inputError,
      outputFeeFiatValue,
      parsedAmount,
      trade,
      inputTax,
      outputTax,
    ]
  );
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") return "ETH";
    if (upper in TOKEN_SHORTHANDS) return upper;
  }
  return "";
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === "string" && !isNaN(parseFloat(urlParam))
    ? urlParam
    : "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
    ? Field.OUTPUT
    : Field.INPUT;
}

const ENS_NAME_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== "string") return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount);
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField
  );

  if (
    inputCurrency === "" &&
    outputCurrency === "" &&
    typedValue === "" &&
    independentField === Field.INPUT
  ) {
    // Defaults to having the native currency selected
    inputCurrency = "LYX";
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = "";
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === "" ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === "" ? null : outputCurrency ?? null,
    },
    typedValue,
    independentField,
    recipient,
  };
}
