import LSP7DigitalAsset from "@lukso/lsp-smart-contracts/artifacts/LSP7DigitalAsset.json";
import { useWeb3React } from "@web3-react/core";
import { CustomChainId } from "constants/chains";
import { Bytes, BytesLike, Contract, ethers } from "ethers";
import { CHAIN_INFO, ChainId } from "wallet/src/constants/chains";
import { ERC725YDataKeys } from "@lukso/lsp-smart-contracts";
import { ERC725 } from "@erc725/erc725.js";
import LSP4DigitalAssetSchemas from "@erc725/erc725.js/schemas/LSP4DigitalAsset.json";
import LSP5ReceivedAssets from "@erc725/erc725.js/schemas/LSP5ReceivedAssets.json";
// import { Token } from "graphql";
import { PortfolioTokenBalancePartsFragment } from "graphql/data/__generated__/types-and-hooks";
import { ipfsUrlToGatewayUrl } from "constants/luksoUtils";
import { RPC_URLS } from "constants/networks";

// import type { LSP7DigitalAsset as LSP7DigitalAssetInterface } from "@/types/contracts";

export const fetchLSP7Assets = async (
  account: string,
  chainId: CustomChainId
): Promise<PortfolioTokenBalancePartsFragment[]> => {
  const rpcUrl = RPC_URLS[chainId as 42 | 4201][0];
  // const rpcProvider = CHAIN_INFO[chainId as CustomChainId].rpcUrls[0] as string;
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  const erc725js = new ERC725(LSP5ReceivedAssets, account, rpcUrl, {
    ipfsGateway: "https://api.universalprofile.cloud/ipfs",
  });

  // fetch the lsp5ReceivedAssets array for each index from 0 to length
  const lsp5ReceivedAssetsERC725 = await erc725js.fetchData(
    "LSP5ReceivedAssets[]"
  );

  const lsp5ReceivedAssets = lsp5ReceivedAssetsERC725.value as string[];

  let lsp7AssetsArray: string[] = [];
  try {
    await Promise.all(
      lsp5ReceivedAssets.map(async (tokenContractAddress: string) => {
        const isLSP7 = await fetchIsLSP7(tokenContractAddress, provider);
        if (isLSP7) {
          return lsp7AssetsArray.push(tokenContractAddress);
        }
      })
    );
  } catch (error) {
    console.error("Error fetching LSP7 assets", error);
  }

  // Process each asset and construct the return array
  const portfolioTokens: PortfolioTokenBalancePartsFragment[] =
    await Promise.all(
      lsp7AssetsArray.map(async (tokenContractAddress: string) => {
        const lsp7Contract = generateLsp7Contract(
          tokenContractAddress,
          provider
        );
        const { balance, metadata, decimals, name, symbol } =
          await fetchBalanceAndMetadataAndDecimals(
            account,
            lsp7Contract,
            chainId
          );

        let logoUrl = "";
        if (metadata) {
          // if metdata cointains a valid ipfs hash, then we can use it to fetch the logo
          if (metadata.LSP4Metadata?.icon[0]?.url) {
            // if logoUrl = metadata.value[0].LSP4Metadata.icon.url; starts with ipfs://

            logoUrl = metadata.LSP4Metadata.icon[0].url.startsWith("ipfs://")
              ? ipfsUrlToGatewayUrl(metadata?.LSP4Metadata?.icon[0]?.url)
              : metadata.LSP4Metadata.icon[0].url;
          }
        }

        // Construct the PortfolioTokenBalancePartsFragment object for this asset
        return {
          __typename: "TokenBalance",
          id: tokenContractAddress,
          quantity: parseInt(ethers.utils.formatUnits(balance, decimals)),
          token: {
            __typename: "Token",
            id: tokenContractAddress,
            chain: fromChainIdToGraphQLChain(chainId), // Simplified, adjust as necessary
            address: tokenContractAddress,
            name: name,
            symbol: symbol,
            standard: "LSP7", // Assuming LSP7 as the standard for these tokens
            decimals,
            logoUrl,
          },
          // Include other fields like denominatedValue and tokenProjectMarket as necessary
        };
      })
    );

  return portfolioTokens;
};

const fromChainIdToGraphQLChain = (chainId: CustomChainId | ChainId) => {
  switch (chainId) {
    case 42:
      return Chain.Lukso;
    case 4201:
      return Chain.LuksoTestnet;
    default:
      return Chain.Lukso;
  }
};

export enum Chain {
  Arbitrum = "ARBITRUM",
  Avalanche = "AVALANCHE",
  Base = "BASE",
  Bnb = "BNB",
  Celo = "CELO",
  Ethereum = "ETHEREUM",
  EthereumGoerli = "ETHEREUM_GOERLI",
  EthereumSepolia = "ETHEREUM_SEPOLIA",
  Optimism = "OPTIMISM",
  Polygon = "POLYGON",
  UnknownChain = "UNKNOWN_CHAIN",
  Lukso = "LUKSO",
  LuksoTestnet = "LUKSO_TESTNET",
}

export const fetchBalanceAndMetadataAndDecimals = async (
  account: string,
  lsp7Contract: Contract,
  chainId: CustomChainId
) => {
  const balance = (await fetchLsp7Balance(account, lsp7Contract)) as BytesLike;
  const { metadata, name, symbol } = await fetchLsp7MetadataAndCreators(
    lsp7Contract,
    chainId
  );
  const decimals = await fetchDecimals(lsp7Contract);
  return { balance, metadata, decimals, name, symbol };
};

export const fetchLsp7Balance = async (
  profileAddress: string,
  contract: Contract
) => {
  const balance = await contract.balanceOf(profileAddress);
  return balance;
};

const fetchIsLSP7 = async (address: string, provider: any) => {
  const lsp7Contract = generateLsp7Contract(address, provider);
  const isLSP7: boolean = await lsp7Contract.supportsInterface("0xb3c4928f");
  return isLSP7;
};

const fetchDecimals = async (contract: Contract) => {
  const decimals = await contract.decimals();
  return decimals;
};

const ipfsConfig = {
  ipfsGateway: "https://api.universalprofile.cloud/ipfs",
};

export const fetchLsp7MetadataAndCreators = async (
  contract: Contract,
  chainId: number
) => {
  const erc725js = new ERC725(
    LSP4DigitalAssetSchemas,
    contract.address,
    RPC_URLS[chainId as 42 | 4201][0],
    ipfsConfig
  );

  let nameAsBytes;
  let symbolAsBytes;

  try {
    const [name, symbol]: {
      name: string;
      symbol: string;
    } = await contract.getDataBatch([
      ERC725YDataKeys.LSP4.LSP4TokenName,
      ERC725YDataKeys.LSP4.LSP4TokenSymbol,
    ]);
    nameAsBytes = name;
    symbolAsBytes = symbol;
  } catch (error) {
    // if error has 'Value of key: LSP4Metadata' then do not console.error
    if (!error.message.includes("Value of key")) {
      console.error("Error fetching metadata and creators", error);
    }
  }

  const name = ethers.utils.toUtf8String(nameAsBytes);
  const symbol = ethers.utils.toUtf8String(symbolAsBytes);
  let metadata;
  try {
    metadata = await erc725js.fetchData(ERC725YDataKeys.LSP4.LSP4Metadata);
    metadata = metadata.value as any;
  } catch (error) {
    if (!error.message.includes("Value of key")) {
      console.error("Error fetching metadata", error);
    }
  }

  return {
    name,
    symbol,
    metadata,
  };
};

export const generateLsp7Contract = (
  address: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  return new ethers.Contract(address, LSP7DigitalAsset.abi, provider);
};
