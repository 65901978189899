// Gets pools by user id

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { IPositionResult } from "./QueryInterfaces";
import { USER_POOLS_PER_QUERY } from "../utils/Contants";

const userPoolsClient = new ApolloClient({
    uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions", // Replace with your desired API URL
    cache: new InMemoryCache(),
});

const userPoolsDocument = gql`
  query PoolsQuery($id: ID!, $first: Int, $skip: Int) {
    positions(
      where: { and: [ {liquidity_not: "0"}, {owner: $id} ] },
      first: $first
      skip: $skip
    ) {
      depositedToken0
      depositedToken1
      id
      liquidity
      owner
      pool {
        tick
        feeTier
      }
      tickLower {
        tickIdx
      }
      tickUpper {
        tickIdx
      }
      token0 {
        id
        name
        symbol
        derivedETH
      }
      token1 {
        id
        name
        symbol
        derivedETH
      }
    }
  }
`

export interface GetUserPoolsQueryParams {
  id: string
  first?: number
  skip?: number
}

export async function fetchUserPools(queryParams: GetUserPoolsQueryParams) : Promise<IPositionResult[]> {
    const id = queryParams.id.toLowerCase();
    const first = queryParams.first ?? USER_POOLS_PER_QUERY;
    const skip = queryParams.skip ?? 0;

    const { data, error, loading } = await userPoolsClient.query({
        variables: { id, first, skip },
        query: userPoolsDocument,
        fetchPolicy: 'cache-first',
    })

    return data?.positions ?? [];
};
