import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UserIsFollowedDocument = gql`
  query QueryFollowers($connectedUser: ID!, $userToCheck: ID!) {
    follows(
      where: {
        and: [
          { follower_: { id: $connectedUser } }
          { followee_: { id: $userToCheck } }
        ]
      }
    ) {
      id
    }
  }
`;

export const userIsFollowedClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/registry",
  cache: new InMemoryCache(),
});

export async function userIsFollowed(
  _connectedUser: string,
  _userToCheck: string
): Promise<boolean> {
  if (!_connectedUser || !_userToCheck) return false;

  const connectedUser = _connectedUser.toLowerCase();
  const userToCheck = _userToCheck.toLowerCase();

  const { data, error, loading } = await userIsFollowedClient.query({
    variables: { connectedUser, userToCheck },
    query: UserIsFollowedDocument,
    fetchPolicy: "no-cache",
  });

  if (error) {
    throw new Error("Failed to fetch followers");
  }

  return data.follows.length !== 0;
}
