import { ChainId } from "@uniswap/sdk-core";
import { CustomChainId } from "./chains";

const INFURA_KEY = "https://rpc.mainnet.lukso.network";

if (typeof INFURA_KEY === "undefined") {
  throw new Error(
    `REACT_APP_INFURA_KEY must be a defined environment variable`
  );
}
const QUICKNODE_MAINNET_RPC_URL =
  process.env.REACT_APP_QUICKNODE_MAINNET_RPC_URL;
if (typeof QUICKNODE_MAINNET_RPC_URL === "undefined") {
  throw new Error(
    `REACT_APP_QUICKNODE_MAINNET_RPC_URL must be a defined environment variable`
  );
}
const QUICKNODE_BNB_RPC_URL = process.env.REACT_APP_BNB_RPC_URL;
if (typeof QUICKNODE_BNB_RPC_URL === "undefined") {
  throw new Error(
    `REACT_APP_BNB_RPC_URL must be a defined environment variable`
  );
}

/**
 * Fallback JSON-RPC endpoints.
 * These are used if the integrator does not provide an endpoint, or if the endpoint does not work.
 *
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const FALLBACK_URLS = {
  [ChainId.MAINNET]: [
    // "Safe" URLs
    "https://api.mycryptoapi.com/eth",
    "https://cloudflare-eth.com",
    // "Fallback" URLs
    "https://rpc.ankr.com/eth",
    "https://eth-mainnet.public.blastapi.io",
  ],
  [CustomChainId.LUKSO_MAINNET]: [
    // "Safe" URLs
    "https://rpc.mainnet.lukso.network",
  ],
  [CustomChainId.LUKSO_TESTNET]: ["https://rpc.testnet.lukso.gateway.fm"],
};

/**
 * Known JSON-RPC endpoints.
 * These are the URLs used by the interface when there is not another available source of chain data.
 */
export const RPC_URLS = {
  [ChainId.MAINNET]: [
    `${INFURA_KEY}`,
    QUICKNODE_MAINNET_RPC_URL,
    ...FALLBACK_URLS[ChainId.MAINNET],
  ],
  [CustomChainId.LUKSO_MAINNET]: [`https://rpc.mainnet.lukso.network`],
  [CustomChainId.LUKSO_TESTNET]: [`https://rpc.testnet.lukso.gateway.fm`],
};
