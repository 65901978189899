import { t, Trans } from "@lingui/macro";
import { InterfaceElementName } from "@uniswap/analytics-events";
import FeatureFlagModal from "components/FeatureFlagModal/FeatureFlagModal";
import { PrivacyPolicyModal } from "components/PrivacyPolicy";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { Box } from "nft/components/Box";
import { Column, Row } from "nft/components/Flex";
import {
  BarChartIcon,
  DiscordIconMenu,
  EllipsisIcon,
  FlagIcon,
  GithubIconMenu,
  GovernanceIcon,
  PoolIcon,
  TwitterIconMenu,
} from "nft/components/icons";
import { body, bodySmall } from "nft/css/common.css";
import { themeVars } from "nft/css/sprinkles.css";
import { ReactNode, useReducer, useRef } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import { useToggleModal } from "state/application/hooks";
import styled, { useTheme } from "styled-components";
import { ThemedText } from "theme/components";
import { isDevelopmentEnv, isStagingEnv } from "utils/env";
import { openDownloadApp } from "utils/openDownloadApp";

import { ReactComponent as UniswapAppLogo } from "../../assets/svg/uniswap_app_logo.svg";
import { ApplicationModal } from "../../state/application/reducer";
import * as styles from "./MenuDropdown.css";
import { NavDropdown } from "./NavDropdown";
import { NavIcon } from "./NavIcon";

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps["to"];
  href?: string;
  close?: () => void;
  children: ReactNode;
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to} className={styles.MenuRow}>
          <Row onClick={close}>{children}</Row>
        </NavLink>
      ) : (
        <Row
          cursor="pointer"
          as="a"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.MenuRow}
        >
          {children}
        </Row>
      )}
    </>
  );
};

const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`;
const PrimaryMenuRowText = ({ children }: { children: ReactNode }) => {
  return (
    <StyledBox className={`${styles.PrimaryText} ${body}`}>
      {children}
    </StyledBox>
  );
};

PrimaryMenuRow.Text = PrimaryMenuRowText;

const SecondaryLinkedText = ({
  href,
  onClick,
  children,
}: {
  href?: string;
  onClick?: () => void;
  children: ReactNode;
}) => {
  return (
    <Box
      as={href ? "a" : "div"}
      href={href ?? undefined}
      target={href ? "_blank" : undefined}
      rel={href ? "noopener noreferrer" : undefined}
      className={`${styles.SecondaryText} ${bodySmall}`}
      onClick={onClick}
      cursor="pointer"
    >
      {children}
    </Box>
  );
};

const Separator = () => {
  return <Box className={styles.Separator} />;
};

const IconRow = ({ children }: { children: ReactNode }) => {
  return <Row className={styles.IconRow}>{children}</Row>;
};

const Icon = ({ href, children }: { href?: string; children: ReactNode }) => {
  return (
    <>
      <Box
        as={href ? "a" : "div"}
        href={href ?? undefined}
        target={href ? "_blank" : undefined}
        rel={href ? "noopener noreferrer" : undefined}
        display="flex"
        flexDirection="column"
        color="neutral1"
        background="none"
        border="none"
        justifyContent="center"
        textAlign="center"
        marginRight="12"
      >
        {children}
      </Box>
    </>
  );
};

export const MenuDropdown = () => {
  const theme = useTheme();
  const [isOpen, toggleOpen] = useReducer((s) => !s, false);
  const togglePrivacyPolicy = useToggleModal(ApplicationModal.PRIVACY_POLICY);
  const openFeatureFlagsModal = useToggleModal(ApplicationModal.FEATURE_FLAGS);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined);

  return (
    <>
      <Box position="relative" ref={ref} marginRight="4">
        <NavIcon
          isActive={isOpen}
          onClick={toggleOpen}
          label={isOpen ? t`Show resources` : t`Hide resources`}
        >
          <EllipsisIcon viewBox="0 0 20 20" width={24} height={24} />
        </NavIcon>

        {isOpen && (
          <NavDropdown
            top={{ sm: "unset", lg: "56" }}
            bottom={{ sm: "50", lg: "unset" }}
            right="0"
          >
            <Column gap="8">
              <Column paddingX="8" gap="4">
                {/* <Box display={{ sm: 'none', lg: 'flex', xxl: 'none' }}>
                  <PrimaryMenuRow to="/pool" close={toggleOpen}>
                    <Icon>
                      <PoolIcon width={24} height={24} fill={theme.neutral1} />
                    </Icon>
                    <PrimaryMenuRow.Text>
                      <Trans>Pool</Trans>
                    </PrimaryMenuRow.Text>
                  </PrimaryMenuRow>
                </Box> */}
                <PrimaryMenuRow to="" close={toggleOpen}>
                  <div>
                    <ThemedText.BodyPrimary>
                      <Trans>Vote in governance</Trans>
                    </ThemedText.BodyPrimary>
                    <ThemedText.LabelSmall>
                      <Trans>Coming soon</Trans>
                    </ThemedText.LabelSmall>
                  </div>
                </PrimaryMenuRow>
                {/* <PrimaryMenuRow to="/roadmap" close={toggleOpen}>
                  <div>
                    <ThemedText.BodyPrimary>
                      <Trans>Roadmap</Trans>
                    </ThemedText.BodyPrimary>
                  </div>
                </PrimaryMenuRow> */}
                <PrimaryMenuRow to="/create-token" close={toggleOpen}>
                  <div>
                    <ThemedText.BodyPrimary>
                      <Trans>Create Token</Trans>
                    </ThemedText.BodyPrimary>
                  </div>
                </PrimaryMenuRow>
                {/* <PrimaryMenuRow href="https://info.uniswap.org/#/">
                  <Icon>
                    <BarChartIcon
                      width={24}
                      height={24}
                      color={theme.neutral1}
                    />
                  </Icon>
                  <PrimaryMenuRow.Text>
                    <Trans>View more analytics</Trans>
                  </PrimaryMenuRow.Text>
                </PrimaryMenuRow> */}
                {/* <Box
                  onClick={() =>
                    openDownloadApp({
                      element:
                        InterfaceElementName.UNISWAP_WALLET_NAVBAR_MENU_DOWNLOAD_BUTTON,
                    })
                  }
                >
                  <PrimaryMenuRow close={toggleOpen}>
                    <>
                      <Icon>
                        <UniswapAppLogo width="24px" height="24px" />
                      </Icon>
                      <div>
                        <ThemedText.BodyPrimary>
                          <Trans>Download Uniswap</Trans>
                        </ThemedText.BodyPrimary>
                        <ThemedText.LabelSmall>
                          <Trans>Available on iOS and Android</Trans>
                        </ThemedText.LabelSmall>
                      </div>
                    </>
                  </PrimaryMenuRow>
                </Box> */}
              </Column>
              <Separator />
              <Box
                display="flex"
                flexDirection={{ sm: "row", md: "column" }}
                flexWrap="wrap"
                alignItems={{ sm: "center", md: "flex-start" }}
                paddingX="8"
              >
                {/* <SecondaryLinkedText href="https://help.uniswap.org/en/">
                  <Trans>Help center</Trans> ↗
                </SecondaryLinkedText>
                <SecondaryLinkedText href="https://docs.uniswap.org/">
                  <Trans>Documentation</Trans> ↗
                </SecondaryLinkedText>
                <SecondaryLinkedText href="https://uniswap.canny.io/feature-requests">
                  <Trans>Feedback</Trans> ↗
                </SecondaryLinkedText> */}
                {/* <SecondaryLinkedText
                  onClick={() => {
                    toggleOpen();
                    togglePrivacyPolicy();
                  }}
                >
                  <Trans>Legal</Trans> ↗
                </SecondaryLinkedText> */}
                {/* {(isDevelopmentEnv() || isStagingEnv()) && (
                  <SecondaryLinkedText
                    onClick={() => {
                      toggleOpen();
                      openFeatureFlagsModal();
                    }}
                  >
                    <Trans>Feature Flags</Trans>
                  </SecondaryLinkedText>
                )} */}
              </Box>
              <IconRow>
                <Icon href="https://discord.gg/JxfZzwxfsn">
                  <DiscordIconMenu
                    className={styles.hover}
                    width={24}
                    height={24}
                    color={themeVars.colors.neutral2}
                  />
                </Icon>
                <Icon href="https://twitter.com/UniversalSwaps">
                  <TwitterIconMenu
                    className={styles.hover}
                    width={24}
                    height={24}
                    color={themeVars.colors.neutral2}
                  />
                </Icon>
                <Icon href="https://github.com/Universal-Swaps">
                  <GithubIconMenu
                    className={styles.hover}
                    width={24}
                    height={24}
                    color={themeVars.colors.neutral2}
                  />
                </Icon>
              </IconRow>
            </Column>
          </NavDropdown>
        )}
      </Box>
      <PrivacyPolicyModal />
      <FeatureFlagModal />
    </>
  );
};
