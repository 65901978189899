// Gets all transactions
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { ITransactionResult } from "./QueryInterfaces";
import { TRANSACTIONS_PER_QUERY } from "../utils/Contants";

const LatestTransactionsDocument = gql`
  query GetLatestTransactions($first: Int, $skip: Int) {
    transactions(orderBy: timestamp, first: $first, orderDirection: desc, skip: $skip) {
      burns {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          symbol
          id
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      mints {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        timestamp
      }
      swaps {
        amount0
        amount1
        amountUSD
        origin
        token0 {
          id
          symbol
        }
        token1 {
          symbol
          id
        }
        timestamp
      }
      timestamp
    }
  }
`;


const latestTransactionsClient = new ApolloClient({
    uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/transactions", // Replace with your desired API URL
    cache: new InMemoryCache(),
});

export interface GetTradesQueryParams {
  first?: number
  skip?: number
}

export async function fetchLatestTransactions(queryParams?: GetTradesQueryParams) : Promise<ITransactionResult[]> {
    const first = queryParams?.first ?? TRANSACTIONS_PER_QUERY;
    const skip = queryParams?.skip ?? 0;

    
    const { data, error, loading } = await latestTransactionsClient.query({
        query: LatestTransactionsDocument,
        variables: { first, skip },
        fetchPolicy: 'no-cache',
    })

    if (!error && !loading)
        return data.transactions;
    return [];
}
